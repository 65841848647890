.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1100;
  transition: opacity 0.15s linear;
}
.overlay__inner{
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(0, 130, 255, 0.13);
  border-top-color: #0082ff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}
.overlay_light {
  background: rgba(255, 255, 255, 0.5);
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}