/*
 * ALL WORKING STYLES ARE IN style.MIN.css
 *
 * Styles here NEWLY added by Den
*/

/* All media breakpoints below */

/* col */
/* @media (min-width: 1px) and (max-width: 575px) { } */

/* col-sm */
/* @media (min-width: 576px) and (max-width: 767px) { } */

/* col-md */
/* @media (min-width: 768px) and (max-width: 991px) { } */

/* col-lg */
/* @media (min-width: 992px) and (max-width: 1199px) { } */

/* col-xl */
/* @media (min-width: 1200px) { } */


/* RSVP Temporary Disabled - DEPRECATED */
/* @media (max-width: 1024px) {
    #rsvp-disabled {
        display: block !important;
    }
    
    #rsvp {
        display: none;
    }
} */
@media (min-width: 576px){body.city-landing .row8 {padding-left: 20px;}}
@media (max-width: 991px) and (min-width: 576px) { body.page-id-155 .row4-wrapper {padding-bottom: 25px;}}

@media (max-width: 575px){.row8 .card {margin-left: 10px;margin-right: 10px;} .container.services-v2 {padding-left: 20px;} .about-fenix-row.why {padding-bottom: 0;}}

@media (max-width: 991px) {
   .home .cards-row .card.ml-auto,
   body.page-id-155 .cards-row .card.ml-auto {
       margin-left: auto;
       margin-right: auto;
       margin-bottom: 0;
   }
}

@media (max-width: 767px) {
   .home .cards-row .card.mr-auto,
   body.page-id-155 .cards-row .card.mr-auto {
       margin-left: auto;
       margin-right: auto;
   }
}

@media (max-width: 375px){#services-swipe-container .card-body > .card-text {min-height: 264px;}}

body.page-id-155 .cards-row { padding-top: 0 !important; padding-bottom: 0 !important;}

/* DEV: DEPRECATED: Fix: mega menu is semi-transparent and not showing in IE */
/* @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  div[class^="mega-content"] { top: 110px  !important; z-index: 1; } div[class^="mega-content"]{z-index: 11; }} */

@media (max-width: 575px) {
    .overview-3-cards .col-12:nth-child(1):after {
        right: 0;
    }
    
    .overview-3-cards .col-12:nth-child(2):after {
        bottom: -220px;
    }
}

/* Fix: IE does not support "position: sticky;" */
@media screen and (-ms-high-contrast:active), all and (-ms-high-contrast:none) {
   header#masthead {
       position: fixed;
       width: 100%;
   }
   /*.row.row-breadcrumbs {padding-top: 110px;}*/
   body:not(.funnel):not(.location-single):not(.product-single):not(.product-list) #content,
   body.product-list .row.row-breadcrumbs,
   body.product-single .row.row-breadcrumbs {padding-top: 110px;}
}

/* Layout fixes by @mundiscode */
@media (max-width: 352px) and (min-width: 321px){.navbar-brand img{width: 70px; height: 38px;} .navbar .button-cta{font-size: 10px;} body #nav-onpage {top: 62px !important;} .navbar > .container{padding-left: 12px !important; padding-right: 12px !important;}}
@media (min-width: 768px) and (max-width: 991px){.map-city-offices-wrapper {margin-bottom: 40px !important;} .row10-inner-column { padding-left: 12px; padding-right: 12px; } .row10-inner-column .reco-stars {float: left;}}
@media (min-width: 768px) and (max-width: 1199px) {
.reco-stars {float: left;}
.one-reco-review .row10-inner-column {padding-left: 12px; padding-right: 13px;}
.one-reco-review .row10-inner-column small {margin-right: 15px; }
}
@media (min-width: 576px) and (max-width: 675px) {.city-landing .location-wrapper {max-width: 145px;} .city-landing .location-wrapper .location-img {max-width: 100%;}}
@media (min-width: 1px) and (max-width: 374px) { .wrap-loc-phot-author {max-width: 140px;}}
@media (min-width: 375px) and (max-width: 575px) { .wrap-loc-phot-author {max-width: 165px;}}
@media (min-width: 576px) and (max-width: 675px) { 
    .wrap-loc-phot-author {max-width: 140px;}
    .funeral-type .location-wrapper {max-width: 135px;} .funeral-type .one-location:first-child .location-wrapper {margin-left: 35px;} .funeral-type .one-location:not(:first-child) .location-wrapper {margin-left: 10px;}  .funeral-type .location-wrapper .location-img {max-width: 100%;} .funeral-type #platser .row8-locations {padding-right: 0 !important;}
}
@media (min-width: 321px) and (max-width: 359px) {.row5-wrapper .row5-right-inner-col2-wrapper, .row5-2 {width: 270px;}}
.location-wrapper {position: relative;}
/*@media (max-width: 1199px) {*/
/*    .wrap-loc-phot-author {*/
/*    transform: translateX(-50%) !important;*/
/*    -webkit-transform: translateX(-50%) !important;*/
/*    -moz-transform: translateX(-50%) !important;*/
/*    left: 50% !important;*/
/*    text-align: center;*/
/*    }*/
/*    .location-wrapper {position: relative;}*/
/*}*/
@media (max-width: 352px) and (min-width: 321px) {#mobNavSearchButton:after { bottom: -24px !important;}}

/*#fancybox-content {overflow-y: hidden !important;} */

.wrap-mob-steps {
    height: auto;
    min-height: unset !important;
}

#wrap-mob-nav-city-dropdown {
    margin-top: 25px;
}

#menu-main-menu-mob .active-mob {
    background-color: #eee;
}

/* Moved to live with the above done */

/* For Livestreaming Funerals Blog post */
.cta2-button { width: 100%; }

@media (min-width: 1000px) {
    .hide-br-1000 {
        display: none;
    }
}

.rsvp-page .main-image {
    background-image: url('/wp-content/themes/fenix4/images/rsvp-header-banner.jpg');
    background-position: right;
}

.about-us-page .main-image {
    background-image: url('/wp-content/themes/fenix4/images/about-header-banner-2.jpg');
    background-position: right;
}

.page-id-155 .lazy-background-ph.visible.row11-image {
    background-image: url('/wp-content/themes/fenix4/images/about-bottom-banner-1-big.jpg');
}

@media (max-width: 1440px) and (min-width: 769px) {
    .rsvp-page .main-image {
        background-image: url('/wp-content/themes/fenix4/images/rsvp-header-banner.jpg');
    }
    .about-us-page .main-image {
        background-image: url('/wp-content/themes/fenix4/images/about-header-banner-2.jpg');
    }
    .page-id-155 .lazy-background-ph.visible.row11-image {
        background-image: url('/wp-content/themes/fenix4/images/about-bottom-banner-1-big.jpg');
    }
}

@media (max-width: 768px) and (min-width: 1px) {
    .rsvp-page .main-image {
        background-image: url('/wp-content/themes/fenix4/images/rsvp-header-banner.jpg');
    }
    .about-us-page .main-image {
        background-image: url('/wp-content/themes/fenix4/images/about-header-banner-2.jpg');
    }
    .page-id-155 .lazy-background-ph.visible.row11-image {
        background-image: url('/wp-content/themes/fenix4/images/about-bottom-banner-1-big.jpg');
    }
}


