/************
	Footer
***********/
#colophon {
	background-color: #fff;
	position: relative;
}

.footer-copyright {
	padding: 38px 20px;
	background-color: $deepBlue;

	p {
		font-family: 'Inter', 'rawline';
		font-size: 14px;
		letter-spacing: 0.78px;
		text-transform: uppercase;
		@media (min-width: $tablet720) {
			font-size: 18px;
		}
	}
}

.footer {

	&-grid {

		&__container {
			width: 100%;
			margin-right: auto;
			margin-left: auto;
		}

		&__widget {
			padding: 0 10px;
			max-width: 550px;
			width: 100%;
			margin: 0 auto;
			@media (min-width: $medium) {
				max-width: 1300px;
				padding: 80px 25px 0;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}

		&__brand {
			img {
				max-width: 150px;
				@media (min-width: $tablet720) {
					max-width: 215px;
				}
			}
		}

		&__logo {
			padding: 47px 0 35px;
			@media (min-width: $medium) {
				width: 100%;
				padding: 0 0 35px;
			}
			&>div {
				text-align: center;
				@media (min-width: $medium) {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 30px;
				}
			}
		}

		&__row {
			display: flex;
			flex-flow: column wrap;
			max-height: 1100px;
			padding-bottom: 30px;
			@media (min-width: $medium) {
				max-height: 530px;
				width: 100%;
			}
		}

		&__col {
			width: 50%;
			@media (min-width: $medium) {
				width: 25%;
			}


			&:nth-child(1) {
				order:1;
			}

			&:nth-child(2) {
				order:3;
				@media (min-width: $medium) {
					order:2;
					width: 25%;
				}
			}

			&:nth-child(3) {
				order:2;
				@media (min-width: $medium) {
					order:3;
				}
			}

			&:nth-child(4) {
				order:4;
				@media (min-width: $medium) {
					width: 25%;
				}
			}
		}

		&__item {
			padding-left: 10px;
			@media (min-width: $tablet720) {
				border-left: 1.5px solid $deepBlue;
				padding-left: 20px;
			}
		}

		&__list {
			font-size: 16px;
			line-height: 31px;
			list-style-type: none;
			padding-left: 0;
			margin-bottom: 27px;
			@media (min-width: $medium) {
				font-size: 18px;
				line-height: 1;
			}

			li {
				overflow: hidden;
				text-overflow: ellipsis;
				padding-bottom: 15px;

				button {
					background-color: transparent;
					border-width: 0;
					padding: 0;
					&:hover {
						text-decoration: underline;
					}
				}
			}

			a {
				font-family: 'Inter', 'rawline';
				line-height: 1.1;

				&:hover {
					text-decoration: underline;
					color:$deepBlueHover;
				}
			}
		}

		&__subtitle {
			margin-bottom: 10px;

			a {
				font-family: 'Inter Bold', 'rawline';
				font-size: 16px;
				line-height: 19px;
				text-transform: uppercase;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				display: inline-block;
				max-width: 130px;
				@media (min-width: $phone) {
					max-width: unset;
				}

				&:hover {
					text-decoration: underline;
					color:$deepBlueHover;
				}
			}
		}

		&__phone {

			a {
				display: inline-block;

				span {
					font-family: 'Inter Bold', 'rawline';
					font-size: 36px;
					line-height: 150%;
					letter-spacing: 0.36px;
					color: #85AEFF;
					position: relative;
					padding-left: 43px;
					@media (min-width: $tablet720) {
						font-size: 40px;
					}

					@media (min-width: $medium) {
						font-size: 32px;
						line-height: 1.1;
					}

					&::before {
						content: '';
						position: absolute;
						top: 5px;
						left: 0;
						width: 31px;
						height: 31px;
						background-image: url("data:image/svg+xml,%3Csvg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.2429 20.4739C22.2441 19.4878 20.9972 19.4878 20.0048 20.4739C19.2477 21.2246 18.4907 21.9753 17.7463 22.7387C17.5428 22.9486 17.371 22.9932 17.1229 22.8532C16.633 22.586 16.1114 22.3697 15.6406 22.0771C13.4458 20.6966 11.6073 18.9216 9.97865 16.924C9.17071 15.9316 8.45184 14.8692 7.94926 13.6732C7.84747 13.4314 7.86656 13.2724 8.06377 13.0752C8.82082 12.3436 9.55878 11.5929 10.3031 10.8422C11.3401 9.79889 11.3401 8.57744 10.2967 7.52775C9.7051 6.92974 9.11346 6.34446 8.52182 5.74646C7.91109 5.13573 7.30672 4.51864 6.68963 3.91428C5.69084 2.94093 4.44394 2.94093 3.4515 3.92064C2.68809 4.67132 1.95649 5.44109 1.18036 6.17906C0.461482 6.85976 0.0988627 7.69315 0.0225218 8.6665C-0.0983514 10.2506 0.289715 11.7456 0.836825 13.2024C1.95649 16.2179 3.66144 18.8962 5.72901 21.3518C8.52181 24.6727 11.8554 27.3001 15.7551 29.1959C17.511 30.0483 19.3304 30.7036 21.3089 30.8117C22.6703 30.8881 23.8536 30.5445 24.8015 29.4821C25.4504 28.7569 26.182 28.0953 26.8691 27.4018C27.887 26.3712 27.8933 25.1243 26.8818 24.1065C25.6731 22.8914 24.458 21.6826 23.2429 20.4739Z' fill='%2385AEFF'/%3E%3Cpath d='M22.0278 15.4036L24.3753 15.0028C24.0063 12.8462 22.9884 10.8931 21.4425 9.34084C19.8075 7.70587 17.74 6.67527 15.4625 6.35718L15.1317 8.71739C16.8939 8.96549 18.497 9.76071 19.763 11.0267C20.959 12.2227 21.7415 13.7368 22.0278 15.4036Z' fill='%2385AEFF'/%3E%3Cpath d='M25.6986 5.19932C22.9885 2.48921 19.5595 0.777903 15.7742 0.249878L15.4434 2.61009C18.7134 3.06813 21.6779 4.55042 24.0191 6.88518C26.2393 9.10543 27.6961 11.911 28.2242 14.9964L30.5717 14.5956C29.9546 11.0203 28.2687 7.77582 25.6986 5.19932Z' fill='%2385AEFF'/%3E%3C/svg%3E");
						background-size: 31px 31px;
						background-position: center;
						@media (min-width: $tablet720) {
							top: 8px;
						}
						@media (min-width: $medium) {
							top: 4px;
						}
					}
				}
			}
		}

		&__title {
			font-family: 'PP Right Gothic Medium', 'rawline';
			font-size: 36px;
			line-height: 1;
			letter-spacing: -0.02em;
			margin-bottom: 0;
			@media (min-width: $tablet720) {
				font-size: 48px;
			}
			@media (min-width: $medium) {
				font-size: 36px;
				margin-bottom: 5px;
			}

		}

		&__button {
			padding: 40px 0;
			@media (min-width: $medium) {
				padding: 0;
			}

			button {
				&.footer-cta {
					padding: 10px 15px;
					@media (min-width: $medium) {
						padding: 25px 50px;
					}
				}
			}
		}
	}
}

.t-c-link a,
.t-c-link a:hover {
	text-decoration: underline;
	text-transform: uppercase;
}

.copyright {

	&-text {
		text-transform: uppercase;
	}
}

@media (max-width:575px) {
	.copy-container {
		padding-bottom: 70px;
	}

	.copyright-text {
		position: relative;
		padding-top: 60px;
	}

	.t-c-link {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}

	.t-c-link.t-c-link-two {
		margin-top: 30px;
	}
}

@media (min-width:576px) {
	.t-c-link {
		margin-left: 40px;
	}
}