.update-zindex{
    z-index: 100 !important;
}
.update-shadow{
    box-shadow: none !important;
}
.search-autocomplete-wrapper{
    display: none;
    position: absolute;
    width: 100%;
    top: 120%;
    border-radius: .25rem;
    border: 1px solid #ced4da;
    min-height: 30px;
    max-height: 300px;
    overflow: auto;
    background: #fff;
}
.search-autocomplete-wrapper.show{
    display: block;
}
.search-autocomplete__loading{
    padding: 15px;
}
.search-autocomplete-item{
    display: block;
    padding: 9px 15px;
    color: #37373C;
}
.search-autocomplete-item:hover{
    text-decoration: none;
    color: #37373C;
    background-color: #f9f9f9;
}
.search-autocomplete-item span{
    padding-left: 15px;
    font-size: 12px;
    color: #66666b;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 10px;
}
.lds-ellipsis div {
    position: absolute;
    /*top: 33px;*/
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ced4da;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 5px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 5px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 20px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 35px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}