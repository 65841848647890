/************
  Customizations for Cookiebot Banner
***********/
/* Branding on the Cookiebot banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/* Branding on the Cookiebot Privacy trigger */
#CookiebotWidget .CookiebotWidget-main-logo svg {
  display: none !important;
}

/* Move Cookiebot Widget to Footer instead of floating */
#CookiebotWidget {
  position: absolute !important;
  bottom: 30px !important;
  @media (max-width: 575px) {
    bottom: 70px !important;
  }
  @media (min-width: 576px) and (max-width: 600px) {
    bottom: 30px !important;
  }
}
