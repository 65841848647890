@font-face{font-family:'fenix';src:url('../../font/fenix.eot?67016659');src:url('../../font/fenix.eot?67016659#iefix') format('embedded-opentype'),
url('../../font/fenix.woff2?67016659') format('woff2'),
url('../../font/fenix.woff?67016659') format('woff'),
url('../../font/fenix.ttf?67016659') format('truetype'),
url('../../font/fenix.svg?67016659#fenix') format('svg');font-weight:normal;font-style:normal;font-display: swap;}
.fenix-icon{font-family:"fenix";font-style:normal;font-weight:normal;speak:none;display:inline-block;text-decoration:inherit;width:1em;text-align:center;font-variant:normal;text-transform:none;line-height:1em;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
[class^="icon-"]:before, [class*=" icon-"]:before{font-family:"fenix";font-style:normal;font-weight:normal;speak:none;display:inline-block;text-decoration:inherit;width:1em;margin-right:.2em;text-align:center;font-variant:normal;text-transform:none;line-height:1em;margin-left:.2em;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.icon-star:before{content:'\e800';}
.icon-star-empty:before{content:'\e801';}
.icon-star-half:before{content:'\e802';}
.icon-fixed:before{content:'\e803';}
.icon-plan:before{content:'\e804';}
.icon-full:before{content:'\e805';}
.icon-search:before{content:'\e806';}
.icon-phone:before{content:'\e807';}
.icon-home:before{content:'\e808';}
.icon-cart:before{content:'\e809';}
.icon-help:before{content:'\e80a';}
.icon-location:before{content:'\e80b';}
.icon-email:before{content:'\e80c';}
.icon-down:before{content:'\e80d';}
.icon-info:before{content:'\e80e';}
.icon-articles:before{content:'\e80f';}
.icon-right:before{content:'\e810';}
.icon-filter:before{content:'\e811';}
.icon-trash-alt-regular:before{content:'\e812';}
.icon-basket:before{content:'\e813';}
.icon-morevert:before{content:'\e814';}
.icon-check:before{content:'\e815';}
.icon-eye:before{content:'\e816';}
.icon-cancel:before{content:'\e817';}
.icon-clipboard:before{content:'\e818';}
.icon-trash-empty:before{content:'\e819';}
.icon-zoom-in:before{content:'\e81a';}
.icon-zoom-out:before{content:'\e81b';}
.icon-phone-circle:before{content:'\e824';}
.icon-location-outlines:before{content:'\e825';}
.icon-care:before{content:'\e826';}
.icon-check-empty:before{content:'\f096';}
.icon-star-half-alt:before{content:'\f123';}
.color-yellow{color:#fdc93e;}
.home-image{width:124px;height:124px;display:block;margin:0 auto 16px auto;}
.home-icon-content{font-size:42px;color:#164149;}
.home-icon-wrap{display:block;margin:0 auto 16px auto;}
.menu-items-margins{margin-left:9px;margin-right:9px;}
@font-face{font-family:'rawline';src:url('../../font/rawline-100.eot');src:url('../../font/rawline-100.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-100.woff2') format('woff2'),
url('../../font/rawline-100.woff') format('woff'),
url('../../font/rawline-100.ttf') format('truetype'),
url('../../font/rawline-100.svg') format('svg');font-weight:100;font-style:normal;}
@font-face{font-family:'rawline';src:url('../../font/rawline-100i.eot');src:url('../../font/rawline-100i.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-100i.woff2') format('woff2'),
url('../../font/rawline-100i.woff') format('woff'),
url('../../font/rawline-100i.ttf') format('truetype'),
url('../../font/rawline-100i.svg') format('svg');font-weight:100;font-style:italic;}
@font-face{font-family:'rawline';src:url('../../font/rawline-200.eot');src:url('../../font/rawline-200.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-200.woff2') format('woff2'),
url('../../font/rawline-200.woff') format('woff'),
url('../../font/rawline-200.ttf') format('truetype'),
url('../../font/rawline-200.svg') format('svg');font-weight:200;font-style:normal;}
@font-face{font-family:'rawline';src:url('../../font/rawline-200i.eot');src:url('../../font/rawline-200i.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-200i.woff2') format('woff2'),
url('../../font/rawline-200i.woff') format('woff'),
url('../../font/rawline-200i.ttf') format('truetype'),
url('../../font/rawline-200i.svg') format('svg');font-weight:200;font-style:italic;}
@font-face{font-family:'rawline';src:url('../../font/rawline-300-.eot');src:url('../../font/rawline-300-.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-300.woff2') format('woff2'),
url('../../font/rawline-300.woff') format('woff'),
url('../../font/rawline-300.ttf') format('truetype'),
url('../../font/rawline-300.svg') format('svg');font-weight:300;font-style:normal;}
@font-face{font-family:'rawline';src:url('../../font/rawline-300i.eot');src:url('../../font/rawline-300i.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-300i.woff2') format('woff2'),
url('../../font/rawline-300i.woff') format('woff'),
url('../../font/rawline-300i.ttf') format('truetype'),
url('../../font/rawline-300i.svg') format('svg');font-weight:300;font-style:italic;}
@font-face{font-family:'rawline';src:url('../../font/rawline-400.eot');src:url('../../font/rawline-400.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-400.woff2') format('woff2'),
url('../../font/rawline-400.woff') format('woff'),
url('../../font/rawline-400.ttf') format('truetype'),
url('../../font/rawline-400.svg') format('svg');font-weight:400;font-style:normal;}
@font-face{font-family:'rawline';src:url('../../font/rawline-400i.eot');src:url('../../font/rawline-400i.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-400i.woff2') format('woff2'),
url('../../font/rawline-400i.woff') format('woff'),
url('../../font/rawline-400i.ttf') format('truetype'),
url('../../font/rawline-400i.svg') format('svg');font-weight:400;font-style:italic;}
@font-face{font-family:'rawline';src:url('../../font/rawline-500.eot');src:url('../../font/rawline-500.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-500.woff2') format('woff2'),
url('../../font/rawline-500.woff') format('woff'),
/*url('../../font/rawline-.ttf') format('truetype'),*/
url('../../font/rawline-500.svg') format('svg');font-weight:500;font-style:normal;}
@font-face{font-family:'rawline';src:url('../../font/rawline-500i.eot');src:url('../../font/rawline-500i.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-500i.woff2') format('woff2'),
url('../../font/rawline-500i.woff') format('woff'),
url('../../font/rawline-500i.ttf') format('truetype'),
url('../../font/rawline-500i.svg') format('svg');font-weight:500;font-style:italic;}
@font-face{font-family:'rawline';src:url('../../font/rawline-600.eot');src:url('../../font/rawline-600.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-600.woff2') format('woff2'),
url('../../font/rawline-600.woff') format('woff'),
url('../../font/rawline-600.ttf') format('truetype'),
url('../../font/rawline-600.svg') format('svg');font-weight:600;font-style:normal;}
@font-face{font-family:'rawline';src:url('../../font/rawline-600i.eot');src:url('../../font/rawline-600i.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-600i.woff2') format('woff2'),
url('../../font/rawline-600i.woff') format('woff'),
url('../../font/rawline-600i.ttf') format('truetype'),
url('../../font/rawline-600i.svg') format('svg');font-weight:600;font-style:italic;}
@font-face{font-family:'rawline';src:url('../../font/rawline-700.eot');src:url('../../font/rawline-700.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-700.woff2') format('woff2'),
url('../../font/rawline-700.woff') format('woff'),
url('../../font/rawline-700.ttf') format('truetype'),
url('../../font/rawline-700.svg') format('svg');font-weight:700;font-style:normal;}
@font-face{font-family:'rawline';src:url('../../font/rawline-700i.eot');src:url('../../font/rawline-700i.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-700i.woff2') format('woff2'),
url('../../font/rawline-700i.woff') format('woff'),
url('../../font/rawline-700i.ttf') format('truetype'),
url('../../font/rawline-700i.svg') format('svg');font-weight:700;font-style:italic;}
@font-face{font-family:'rawline';src:url('../../font/rawline-800.eot');src:url('../../font/rawline-800.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-800.woff2') format('woff2'),
url('../../font/rawline-800.woff') format('woff'),
url('../../font/rawline-800.ttf') format('truetype'),
url('../../font/rawline-800.svg') format('svg');font-weight:800;font-style:normal;}
@font-face{font-family:'rawline';src:url('../../font/rawline-800i.eot');src:url('../../font/rawline-800i.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-800i.woff2') format('woff2'),
url('../../font/rawline-800i.woff') format('woff'),
url('../../font/rawline-800i.ttf') format('truetype'),
url('../../font/rawline-800i.svg') format('svg');font-weight:800;font-style:italic;}
@font-face{font-family:'rawline';src:url('../../font/rawline-900.eot');src:url('../../font/rawline-900.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-900.woff2') format('woff2'),
url('../../font/rawline-900.woff') format('woff'),
url('../../font/rawline-900.ttf') format('truetype'),
url('../../font/rawline-900.svg') format('svg');font-weight:900;font-style:normal;}
@font-face{font-family:'rawline';src:url('../../font/rawline-900i.eot');src:url('../../font/rawline-900i.eot?#iefix') format('embedded-opentype'),
url('../../font/rawline-900i.woff2') format('woff2'),
url('../../font/rawline-900i.woff') format('woff'),
url('../../font/rawline-900i.ttf') format('truetype'),
url('../../font/rawline-900i.svg') format('svg');font-weight:900;font-style:italic;}
*{font-family:'rawline', sans-serif;}
.container{padding-right:10px;padding-left:10px;}
p{color:#002733;font-size:16px;line-height:24px;font-weight:400;}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{font-weight:bold;font-family:inherit;margin-bottom:.5rem;}
h2, .h2{font-size:32px;line-height:38px;}
h3, .h3{font-size:28px;line-height:34px;}
h4, .h4{font-size:24px;line-height:30px;}
h5, .h5{font-size:20px;line-height:24px;margin-bottom:.75rem;}
h6, .h6{font-size:16px;line-height:20px;margin-bottom:1rem;}
h5, h6{color:#002733;}
a.sidebar-group-title-link .sidebar-group-title{color:#000000;text-decoration:none;width: 100%;}
a.sidebar-group-title-link:hover,
a.sidebar-topic-link:hover{text-decoration:none;}
.sidebar-topic{color:#616161;font-weight:600;line-height:24px;margin-bottom:17.5px;}
.sidebar-topic-item{color:#37373C;padding-left:19px;padding-right:17px;margin-bottom:18px;background-color:transparent;margin-right:17px;}
.side-bar-item-link{color:#616161;font-size:16px;line-height:24px;text-decoration:none;}
.side-bar-item-link:hover{color:#616161;text-decoration:none;}
.sidebar-topic-item.active-sidebar-topic-item{margin-left:-19px;border-left:4px solid #4a90ef;font-weight:bold;padding-left:33px;}
.sidebar-topic.active-sidebar-topic{margin-left:-19px;border-left:4px solid #4a90ef;font-weight:bold;padding-left:16px;}
.sidebar-group-wrapper{border:1px solid #ddd;padding:24px 0 14.5px 18px;border-radius:3px;margin-bottom:32px;}
.sidebar-items-wrapper{padding-top:6.5px;}
#breadcrumbs{}
.breadcrumb{background-color:transparent;}
.breadcrumb-item > a{color:#343A40;font-weight:600;line-height:19px;}
li[aria-current="page"] a{color:#6C757D;text-decoration:none;cursor:default;}
.posts-list-wrapper{margin-bottom:34px;}
.posts-list-description{max-width:740px;padding:12px 23px 0 19px;}
.posts-list-description > h5{color:#000000;font-weight:700;}
.posts-list-description > p{color:#616161;}
a.posts-list-wrapper:link{text-decoration:none;}
footer h6{color:#FFFFFF;font-weight:bold;line-height:19px;margin-bottom:25px;}
footer div.footer-items{color:#FFFFFF;font-size:13px;line-height:15px;margin-bottom:19px;}
footer h6.inside-stack{margin-top:30px;}
footer a,
footer a:hover{color:#FFFFFF;}
footer .widget-area{padding-top:30px;}
.btn-sm{color:#37373C;font-size:13px;border-radius:3px;}
.btn-sm.btn-filter{font-size:16px;font-weight:bold;border:1px solid #4C84F5;padding-left:54px;padding-right:21px;}
.btn-small-prod{padding-left:26px;padding-right:26px;margin-right:12px;}
.btn-small-article{padding-left:15px;padding-right:15px;margin-right:12px;margin-bottom:11px;}
.author-date{color:#616161;font-size:16px;line-height:24px;margin-bottom:25px;}
.post-content-wrapper{padding-bottom:41px;}
.single-buttons-wrapper{padding-bottom:39px;}
.line{width:100%;border:1px solid #BBC1C6;transform:scaleY(-1);}
.related-articles{padding-top:40px;}
.related-articles-title{color:#37373C;font-size:16px;font-weight:bold;line-height:19px;}
.related-articles-list{padding-top:18px;padding-bottom:40px;}
.related-articles-list-item{text-decoration:underline;color:#007BFF;font-size:16px;font-weight:600;line-height:19px;padding-bottom:20px;}
.related-articles-list-item > a:hover{color:#007BFF;}
ul.related-articles-list{padding-left:18px;}
.organiser-wrapper{position:absolute;top:-374px;right:20px;z-index:10;}
.organiser{/*height:464px;*/width:361px;border-radius:3px;background-color:#FFFFFF;box-shadow:0 0 10px 0 rgba(0,0,0,0.25);padding:30px 33px;z-index:10;}
.organiser-in-post-wrapper .organiser{box-shadow:none;}
.organiser-in-post-wrapper{box-shadow:0 0 10px 0 rgba(0,0,0,0.25);margin:40px auto;position:relative;}
.organiser-hr{border-top:1px dotted #979797;opacity:0.4;margin-top:10px;margin-bottom:7px;}
.rating p{padding-top:10px;text-align:center;}
.row0{padding-bottom:10px;padding-left:5px;padding-right:5px;}
.row2{padding-top:50px;padding-bottom:50px;}
.row3-left-column{width:100%;padding-right:9px !important;padding-bottom:9px;padding-top:23px;padding-left:26px !important;border-radius:3px;background-color:rgba(216,216,216,0.2);}
@media (min-width:768px){.funeral-type .row3-left-column{max-width:266px;padding-left:19px !important;}
}
.row3-wrapper{padding-left:5px;padding-right:5px;}
.row3-left-item{}
.row3-right-column{width:100%;padding-left:23px;}
.row3-right-title{padding-bottom:14px;}
.row3-right-button, .row8-button{color:#4C84F5;font-size:16px;font-weight:600;border-color:#4C84F5;padding-left:71px;padding-right:71px;}
.about-us-page .row3-right-button{padding-left:30px;padding-right:30px;}
.row7-button{color:#ffffff;font-size:16px;font-weight:600;border-color:#4C84F5;width:100%;padding-left:0;padding-right:0;}
.row3-right-button-wrapper{padding-top:30px;}
.row3-right-button:hover,
.row7-button:hover,
.row8-button:hover{color:#fff;font-size:16px;font-weight:600;}
.row4-wrapper{background-color:#F5F5F5;}
.row4-column{padding-bottom:40px;}
.row5-left-column{width:550px;border-radius:3px;background-color:#FFFFFF;box-shadow:0 0 10px 3px rgba(0,0,0,0.15);}
.funeral-type .row5-wrapper{background-color:#F5F5F5;}
.row6-wrapper{background-color:#F5F5F5;padding-bottom:38px;}
.row6-wrapper .row.no-gutters .row.no-gutters{flex:0 1 100%;}
.row6-column-fullwidth{padding-top:80px;padding-bottom:56px;}
.home-row6-column-fullwidth{padding-top:80px;padding-bottom:36px;}
.row7-2{padding-bottom:60px;}
.funeral-type .row7-2{margin-bottom:30px;}
.row7-column-fullwidth{padding-top:80px;padding-bottom:46px;}
.row7-column-middle, .row7-column{width:266px;}
.option-price{font-weight:bold;line-height:24px;text-align:center;padding:5px 50px;border-radius:6px;color:#28a745;font-size:20px;}
.product-single .option-price{text-align:left;padding:0;}
@media (max-width:991px){.single-product-options .button-cta{width:100% !important;}
}
div.card > div.card-list > p{margin-bottom:0;}
.hr{margin-top:8.5px;margin-bottom:6px;border-top:1px solid rgba(65,63,67,0.15);}
.row7-button-wrapper{padding-top:10px;text-align:center;}
.circle-image{width:124px;height:124px;border:1px solid gray;border-radius:62px;margin:0 auto;margin-bottom:16px;}
.main-image{padding-top:0px !important;background-color:#F5F5F5;background-position:left center;background-repeat:no-repeat;background-size:cover;/*background-image:url('/wp-content/themes/fenix4/images/city-header-banner-1.jpg');*/}
body.page-id-171 .main-image,body.page-id-67096 .main-image,body.page-id-67403 .main-image{background-image:url('/wp-content/themes/fenix4/images/city-header-banner-1.jpg');}
.single-location-image{padding-top:0px !important;background-image:url(/wp-content/uploads/2018/10/halland-falkenberg-abildskyrka.jpg);background-position:center center;background-repeat:no-repeat;background-size:cover;height:429px;}
.flower-order-image{padding-top:0px !important;background-image:url(//wp-content/themes/fenix4/images/about-header-banner-1.jpg);background-position:center center;background-repeat:no-repeat;background-size:cover;height:429px;}
.row5-right-inner-col1-wrapper{padding-left:22px;}
.second-comment{display:block;}
.row5{padding-top:80px;padding-bottom:80px;}
.row5-right-column{padding-top:38px;padding-bottom:0;}
.row8{padding-top:40px;padding-bottom:45px;}
.home-row8{padding-top:0;padding-bottom:45px;}
.row8 .card{min-width:266px;max-width:266px;}
.location-wrapper{margin-bottom:20px;}
.location-wrapper a:hover .location-title{color:#37373C !important;}
.row9-wrapper{background-color:#F5F5F5;margin-top:40px;padding-top:60px;padding-bottom:50px;}
.address-icons{font-size:24px;color:#BBC1C6;}
.card{padding:28px 22px 20px;border-radius:3px;background-color:#FFFFFF;box-shadow:0 0 10px 0 rgba(0,0,0,0.15);}
.row10{padding-top:47px;padding-bottom:52px;}
.row10-inner-column{padding-left:16px;padding-right:18px;}
.row11-overlay{height:440px;width:1280px;background-color:rgba(65,63,67,0.6);}
.row11-image{padding-top:0px !important;}
.row11-header2{color:#FF37373;font-weight:bold;line-height:37px;}
.row11-inner-column{padding-top:200px;padding-left:80px;}
.row11-inner-column button{font-size:16px !important;font-weight:600 !important;line-height:19px !important;height:44px;/*width:170px;*/}
.btn-lg-custom{color:#ffffff;font-size:16px !important;font-weight:600 !important;line-height:19px !important;}
.organiser .btn-lg-custom{width:100%;}
.help-ico-text, .help-ico-text2{position:absolute;right:36px;}
.help-ico-text{top:98px;}
.help-ico-text2{top:218px;}
.two-buttons, .three-buttons{width:100%;}
.two-buttons .btn,
.three-buttons .btn{width:50%;font-size:16px;font-weight:600;line-height:24px;}
.three-buttons .btn{width:33.333%}
.two-buttons input[type="radio"].toggle + label,
.three-buttons input[type="radio"].toggle + label{margin-bottom:0 !important;height:39px;margin-left:-1px;}
.two-buttons input[type="radio"].toggle:first-child + label,
.three-buttons input[type="radio"].toggle:first-child + label{border-radius:3px 0 0 3px;}
.two-buttons input[type="radio"].toggle:last-child + label,
.three-buttons input[type="radio"].toggle:last-child + label{border-radius:0 3px 3px 0;}
.three-buttons input[type="radio"]#toggle-4 + label{border-radius:0;}
.two-buttons input[type="radio"].toggle,
.three-buttons input[type="radio"].toggle{display:none;}
.two-buttons .btn-outline-primary,
.three-buttons .btn-outline-primary{border-width:2px !important;z-index:10;}
.two-buttons .btn-light,
.three-buttons .btn-light{color:#37373C;opacity:0.5;border:1px solid #cccccc;background-color:#fff;}
.two-buttons .btn-light:hover,
.three-buttons .btn-light:hover{background-color:#e8eaed;}
.one-button button{width:100%;}
.hidden-item{display:none;}
.form-groups-wrapper{padding-top:26px;}
.form-groups-wrapper .form-group{margin-bottom:13px;}
.last-to-show{display:none;}
.last-to-hide{display:block;}
.header-row{padding-top:48px;}
.prod-primary-inner-column{margin-left:-4px;}
.blog-primary-inner-column{padding-left:6px;padding-right:3px;}
.prod-buttons-row{margin-right:-10px;}
.products-row{padding-top:0;margin-right:-32px;}
.locations-row{padding-top:36px;margin-right:-29px;}
.prod-wrapper{width:170px;position:relative;}
.prod-image-wrapper{width:170px;height:170px;border:2px solid rgba(39,53,56,0.1);border-radius:3px;padding:17.5px;}
.prod-image{background-size:contain;background-position:center;background-repeat:no-repeat;width:135px;height:135px;}
.loca-image-wrapper{width:170px;height:170px;}
.loca-image{background-size:cover;background-position:center center;width:170px;height:170px;border-radius:3px;}
.prod-icon{font-size:23px;position:absolute;top:2px;left:138px;}
.product-item-inner{width:170px;}
a.product-row-link:hover{text-decoration:none;}
a.product-row-link:hover .prod-title{text-decoration:underline;}
.product-row-link .prod-title,
.product-row-link .prod-price,
.product-row-link i{color:#212529;}
.product-row-link i{color:#464646;}
.prod-title{font-weight:bold;padding-top:11px;line-height:19px;}
.prod-price{padding-top:7px;}
.about-fenix-image{background-size:contain;background-position:center center;background-repeat:no-repeat;height:340px;border-radius:3px;}
.steps-image-wrapper{width:100%;padding-top:68px;padding-bottom:5px;}
.steps-image{background-size:contain;background-position:center center;background-repeat:no-repeat;height:30px;}
.step-desc{color:#616161;font-size:13px;line-height:16px;}
.about-fenix-column-inner.why{padding-top:80px;}
.about-fenix-column-inner .steps-row .text-center-left{padding-left:0;padding-right:0;}
.about-fenix-row-wrapper{background-color:#F5F5F5;padding-top:80px;padding-bottom:80px;}
.about-fenix-row-wrapper.why{padding-bottom:80px;}
.about-fenix-row-wrapper.workers{padding-bottom:30px;}
.about-fenix-row.why{padding-bottom:80px;}
.title-col{padding-bottom:60px;}
.cards-row .card{padding:0;width:322px;}
.cards-row .card-body{padding:24px 24px 32px;}
.card-title-custom{padding-top:5px;}
.worker-wraper{padding-bottom:50px;}
.worker-wraper p.card-email,
.worker-wraper p.card-phone{margin-bottom:4px;}
.worker-wraper .address-icons{color:#1B3E44;margin-right:5px;}
.burrial-title{color:#37373C;font-size:28px;font-weight:bold;line-height:33px;}
.burrial-row-wrapper,
.memory-notification-wrapper{background-color:#f5f5f5;padding-top:80px;padding-bottom:80px;}
.burrial-item-wrapper{padding-top:50px;}
.number-circle{width:36px;height:36px;border-radius:18px;background-color:#20545C;color:#fff;}
.contacts-wrapper{padding-top:50px;padding-bottom:50px;}
.contacts-wrapper h4,
.contacts-wrapper h5{margin-bottom:16px;}
.contacts-inner-wrapper{padding-top:42px;}
.contacts-column-inner select.near-location{display: none!important;}
.contacts-column-inner .cities-list--choices  {box-sizing: border-box;height: 50px;border-radius: 3px;position: relative;margin-bottom: 0;background-color: #EDF0F6;position: relative;}
.contacts-column-inner .choices:before {content: ' ';background-image: url('/wp-content/themes/fenix4/images/icon_location_mdpi.png');background-repeat: no-repeat;background-size: 19px 24px;position: absolute;width: 19px;height: 24px;top: 12px;left: 15px;z-index: 99;}
.contacts-column-inner .choices__inner {min-height: 50px;border: none;background-color: #EDF0F6!important;border-radius: 3px;}
.contacts-column-inner .choices[data-type*=select-one] .choices__input {font-size: 16px;letter-spacing: 0;line-height: 22px;font-family: 'open_sansregular', sans-serif;color: #4A4A4A;}
.contacts-column-inner .choices__item--selectable {text-indent: 33px;opacity: 1;}
.contacts-column-inner .choices__list--dropdown .choices__item--selectable {padding-right: unset;text-indent: 0;}
form#rsvp .inputs-row{padding-top:53px;padding-bottom:37px;}
form#rsvp label small{color:#37373C;}
.organizeForm-wrapper{border-radius:3px;background-color:#FFFFFF;box-shadow:0 0 10px 0 rgba(0,0,0,0.25);padding:28px 14px 1px;margin-top:50px;margin-bottom:50px;}
form#organizeForm label small{color:#37373C;}
form#organizeForm input{margin-bottom:20px;}
.valid-feedback, .invalid-feedback{margin-top:-18px !important;}
.flatpickr-wrapper {width: 100%;}
.form-control.flatpickr-input {background-color: #ffffff!important;}
form#rsvp input.form-control,
form#organizeForm input.form-control{color:#616161;font-size:16px;line-height:24px;padding-left:16px;height:47px;}
form#organizeForm .form-title-wrapper{padding-left:16px;padding-right:16px;}
.dotted-hr{box-sizing:border-box;height:2px;width:100%;border:1px dotted #979797;opacity:0.4;transform:scaleY(-1);margin:15px 0 10px;}
.form-column-wrapper{padding-left:16px !important;padding-right:16px !important;}
form#rsvp .form-check-input{margin-top:8px;}
.rsvp-buttons-wrapper{padding-top:50px;}
.rsvp-buttons-wrapper button.btn{font-size:16px;font-weight:600;line-height:19px;text-align:center;padding: 17px 18px;width:100%;}
.rsvp-buttons-wrapper button.btn-outline-primary{color:#4C84F5;padding:10px 60px;}
.rsvp-buttons-wrapper button.btn-primary{color:#FFFFFF;padding:10px 52px;}
.primary-button-wrapper{padding-left:17px;}
input#jag ~ label.form-check-label.error{border:1px solid red;}
section.single-product{margin-right:-30px;}
.single-product-item-inner{width:265px;}
.single-prod-wrapper{width:265px;}
.single-prod-image-wrapper{width:265px;height:265px;border:2px solid rgba(39,53,56,0.1);border-radius:3px;padding:25px;position:relative;}
.single-prod-image-wrapper:before,.fun-single-product-link > .prod-image-wrapper:before{content:"\e81a";font-family:"fenix";font-size:22px;color:#BBC1C6;opacity:0;transition:opacity .15s ease-in-out;display:inline-block;position:absolute;top:0;right:10px;padding-left:3px;}
.single-product-row-link:hover .single-prod-image-wrapper:before,.fun-single-product-link:hover>.prod-image-wrapper:before{opacity:1;}
a.single-product-row-link:hover,a.fun-single-product-link:hover{text-decoration:none;}
@media only screen and (max-width:1024px){.single-prod-image-wrapper:before,.fun-single-product-link>.prod-image-wrapper:before{opacity:1;}
}
.single-prod-image{background-size:contain;background-position:center;background-repeat:no-repeat;width:210px;height:210px;}
.single-prod-dropdown{width:205px;}
.single-quantity-wrapper{width:205px;height:44px;border:1px solid rgba(39,53,56,0.1);border-radius:3px;}
.single-quantity-number{height:42px;color:#4C84F5;font-size:20px;font-weight:600;line-height:42px;border-left:1px solid rgba(39,53,56,0.1);border-right:1px solid rgba(39,53,56,0.1);}
.single-quantity-wrapper .single-quantity-button-wrapper button{width:69px;height:100%;border-radius:0;}
.single-price-button-wrapper{margin-top:77px;width:205px;height:44px;}
.single-price-button-wrapper button{width:100%;height:100%;font-size:16px;font-weight:600;line-height:19px;text-align:center;}
.single-description-row{padding-top:46px;}
.single-description-row p{color:#212529;}
.single-description-row.row.no-gutters{flex:0 1 100%;}
.single-similar-row{padding-top:22px;padding-bottom:100px;}
.product-item-column{margin-bottom:50px;}
.page-id-149 .location-title{font-weight:bold;}
.locations-select{width:215px;background-color:#F4F5F7;border:1px solid #CCCCCC;cursor:pointer;font-weight:600;}
.single-loca-table{width:100%;margin-bottom:17px;}
.single-loca-table tr{width:50%;}
.single-loca-table td{padding-bottom:13px;vertical-align:baseline;}
.single-loca-table td:nth-child(2){text-align:right;}
@media (min-width:992px){.single-loca-table td:nth-child(2){padding-right:35px;}
}
.single-location .container{padding-left:5px;}
.single-location h5{margin-bottom:10px;}
.single-location-map-wrapper{width:100%;height:249px;}
.single-location-map{background-image:url('/wp-content/uploads/2018/10/map-single-location.png');background-position:top left;background-repeat:no-repeat;background-size:cover;height:249px;}
.single-line-wrapper{padding-top:29px;padding-bottom:29px;padding-right:31px;width:100%;}
form#organizeForm .form-group{margin-bottom:0;}
.form-button-wrapper{width:fit-content;margin-top:15px;}
.single-location table{margin-bottom:0;}
.location-link{width:170px;}
.location-wrapper{width:170px;}
.cards-pages .title-wrapper{padding-top:50px;padding-bottom:50px;}
.cards-pages .title-inner{padding-bottom:10px;}
.card-item .card-icon,
.organiser .card-icon{width:70px;height:66px;background-color:#aac8f9;border:2px solid gray;border-radius:3px;margin-bottom:40px;}
.card-item-row{margin-right:-87px;}
.card-item-row > div.col-12:first-child .card p{padding-left:7px;padding-right:7px;}
.card-links{padding-top:45px;}
.card-links h3 { min-height: 20px;}
.card-links div{padding-bottom:17px;}
.card-links a{font-weight:600;text-decoration:underline;}
.card-item .card p{margin-bottom:33px;}
.card-item .card button{padding: 10px 20px;}
.card-item{width:242px;padding-top:62px;}
.card-item .card{padding:38px 25px 24px;}
.card-item-row-wrapper{width:900px;}
.two-cards .card-item-row-wrapper{width:550px;}
.two-cards .card-item-row{margin-right:-105px;}
.modal-dialog.contact-us-popup{max-width:500px;}
@media (min-width:992px){.modal-lg.video-popup{max-width:1080px;}
}
.container.org{position:relative;}
.contact-form .modal-footer button{width: auto;padding: 10px 25px;}
body:not(.product-list) .filter-row-fixed{height:50px;overflow:hidden;position:relative;min-height:50px;}
.filter-row-auto,
body.product-list #filter-row.filter-row-fixed{height:auto;position:relative;padding-top:10px;min-height:50px;}
body.product-list #filter-row.filter-row-fixed{padding-top:0;}
body.product-list #filter-row.filter-row-fixed #filter-col{position:relative;top:0 !important;max-width:100%;flex-basis:100%;text-align:center;}
body.product-list #filter-row.filter-row-fixed #filter-col .filter-col-inner{width:98%;}
body.product-list #filter-row.filter-row-fixed #filter-col a{line-height:35px;margin:5px 0;}
body.product-list #filter-button{display:none;}
.tags-col-absolute{position:absolute;top:10px;}
.tags-col-relative{position:relative;}
#filter-button{position:absolute;right:27px;top:8px;}
.filter-col-inner{width:95%;padding-bottom:5px;}
#filter-col a{display:inline-block;}
.logo-wrapper{width:100%;height:100px;padding-top:0px !important;background-position:center center;background-repeat:no-repeat;background-size:contain;margin-bottom:16px;-webkit-filter:grayscale(100%);filter:grayscale(100%);}
.media-heading{margin:0 auto 20px;padding:0 10px !important;}
.card-with-logo{width:100%;}
.card-with-logo .logo-wrapper{margin-bottom:0;}
.lp-title-wrapper{padding-top:30px;}
.container-landing .row3{padding-bottom:80px;}
.our-mission-wrapper{padding-top:100px;}
.page-id-174 .our-mission-wrapper,
.page-id-67096 .our-mission-wrapper,
.page-id-171 .our-mission-wrapper{padding-top:30px;}
.flower-order-title-wrapper{margin-top:35px;}
.flower-order-btn-wrapper button{margin-top:70px;width:170px;}
.cart-small-image{height:44px;width:44px;border:2px solid rgba(39,53,56,0.1);background-position:center center;background-repeat:no-repeat;background-size:contain;}
.cart-remove-button{cursor:pointer;color:#a2a2a2;font-weight:bold;line-height:40px;}
.cart-product-title{color:#37373C;font-size:16px;line-height:19px;font-weight:bold;}
.cart-row{margin-bottom:15px;}
.fake-mega-div{width:100%;height:35px;background-color:transparent;position:absolute;}
.carousel-control-prev-icon,
.carousel-control-next-icon{height:100px;width:100px;}
.carousel-control-next-icon:after{content:'\203A';font-size:55px;color:#616161;}
.carousel-control-prev-icon:after{content:'\2039';font-size:55px;color:#616161;}
#swiper-container{display:none;}
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{bottom:1px !important;}
.flower-order .main-image{background-image:url('//wp-content/themes/fenix4/images/about-header-banner-1.jpg');}
.clear-search-input,
.close-search-result{position:absolute;cursor:pointer;height:36px;color:#37373C;width:40px;line-height:36px;text-align:center;}
.clear-search-input{font-size:13px;right:80px;}
.close-search-result{font-weight:600;right:45px;}
#whitediv{overflow-x:hidden;overflow-y:auto;position:fixed !important;right:0;bottom:0;left:0;outline:0;}
#whitediv{width:100%;height:100vh;background-color:white;z-index:1010;display:none;top:110px;}
.swiper-container{width:100%;height:100%;}
.swiper-slide{text-align:center;font-size:18px;background:#fff;width:80%;display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;-webkit-align-items:center;align-items:center;}
#media-logos-swipe-container{padding-right:0;padding-left:0;}
.row9-wrapper > .container{padding-right:0;padding-left:0;}
#media-logos-swipe-container .swiper-slide{width:65%;padding:0 30px;background-color:transparent;}
form#rsvp .form-group{position:relative;}
.error{color:red;font-size:13px;line-height:16px;}
input.form-control.error{font-size: inherit;}
.modal-body .form-group {position: relative; margin-bottom: 1.7rem;}
.form-group--time {margin-bottom: 0.2rem;}
.contact-us-popup button.close {font-size: 35px;line-height: 0.7;}
.form-group__label, .form-select__label{top: 13px;left: 8px;position: absolute;margin: 0;padding: 0 3px;pointer-events: none;-webkit-transition: 0.2s ease all;transition: 0.2s ease all;font-size: 1rem;}
#bokaMoteForm .form-control{height: 50px;}
textarea.form-control {height: auto !important;}
.form-group__label.active-label, .form-control:focus + .form-group__label, .form-select__label.active-label {top: -10px;font-size: 12px;background-color: #fff;}
label.error{margin-top:2px;position:absolute;}
label#jag-error{top:20px;}
.form-group--time label {width: 100%;}
.form-group--time .flatpickr-wrapper{width: 100%;display: none;margin-top: 0.5rem;}
#bokaMoteForm .flatpickr-input {background-color: #fff;padding-top: 7px;}
select#boka-hjalpselect, select#boka-contactselect {-webkit-appearance: none;-moz-appearance: none;appearance: none; padding: 3px 22px 0 10px;font-size: 16px;background: url('/wp-content/themes/fenix4/images/icon_dropdown_large_mdpi.png');background-repeat: no-repeat;background-position: 95% 50%;background-size: 12px 7px;}
select.form-control.error {color: #ced4da;line-height: 1.5;font-size: 1rem;}
.flatpickr-months, .flatpickr-weekdays, .flatpickr-day.selected, span.flatpickr-weekday, input.numInput.cur-year, select.flatpickr-monthDropdown-months, select.flatpickr-monthDropdown-months,option.flatpickr-monthDropdown-month{background-color: #002733!important;color:#fff!important;opacity:1;box-shadow: unset;}
select.flatpickr-monthDropdown-months{background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIuMDExIDUxMi4wMTEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMi4wMTEgNTEyLjAxMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+PGcgdHJhbnNmb3JtPSJtYXRyaXgoMC43MTc2NTkgLTEuNzU3NzZlLTE2IDEuNzU3NzZlLTE2IDAuNzE3NjU5IDcyLjI4MDkgNzIuMjgwOSkiPjxnPgoJPGc+CgkJPHBhdGggZD0iTTUwNS43NTUsMTIzLjU5MmMtOC4zNDEtOC4zNDEtMjEuODI0LTguMzQxLTMwLjE2NSwwTDI1Ni4wMDUsMzQzLjE3NkwzNi40MjEsMTIzLjU5MmMtOC4zNDEtOC4zNDEtMjEuODI0LTguMzQxLTMwLjE2NSwwICAgIHMtOC4zNDEsMjEuODI0LDAsMzAuMTY1bDIzNC42NjcsMjM0LjY2N2M0LjE2LDQuMTYsOS42MjEsNi4yNTEsMTUuMDgzLDYuMjUxYzUuNDYyLDAsMTAuOTIzLTIuMDkxLDE1LjA4My02LjI1MWwyMzQuNjY3LTIzNC42NjcgICAgQzUxNC4wOTYsMTQ1LjQxNiw1MTQuMDk2LDEzMS45MzMsNTA1Ljc1NSwxMjMuNTkyeiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+PC9nPiA8L3N2Zz4K') no-repeat 90% 30%!important;background-size: 17px 17px !important;-webkit-appearance: none!important;appearance: none!important;padding-right: 25px !important;}
.flatpickr-calendar.arrowTop:after{border-bottom-color: #002733!important;}
.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {top: -4px!important;color: #f8f9fa!important;fill: #f8f9fa!important;}
.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {fill: #fff!important;}
.flatpickr-current-month .numInputWrapper span.arrowUp:after{border-bottom-color: #fff!important;}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {border-top-color: #fff!important;}
.flatpickr-calendar .flatpickr-time {height: 40px!important;border-top: 1px solid #002733!important;}
.flatpickr-current-month, .flatpickr-current-month .numInputWrapper{font-size: 1rem;}
@media (max-width:575px){label#addinfoname-error{left:0;top:15px;}
    body:not(.location-single) label.error{top:15px;}
    .modal-body label.error {top: 37px!important;font-size: 11px;line-height: 11px;}
    .modal-body .form-group {position: relative;margin-bottom: 0rem;}
    .modal-body .form-control {margin-bottom: 0.7rem;}
    .modal-body .form-control.error {margin-bottom: 1.4rem;}
    .form-group.form-group--time {margin-bottom: 0.3rem;}
    form#kontaktaOssForm .modal-body label.error {top: 85px !important;}
    label#jag-error{top:17px;}
    body.page-id-174 label#addinfoname-error{top:-12px;}
    .row.row--select .col-6:first-child {padding-right: 5px;}
    .row.row--select .col-6:last-child {padding-left: 5px;}
    #bokaMoteForm .form-control {
        height: 37px;
    }

    .form-group__label, .form-select__label {
        top: 5px;
    }

    textarea#boka-meddelande {
        margin-top: 8px;
    }

    textarea#boka-meddelande {
        margin-bottom: 0;
    }

    form#bokaMoteForm .modal-footer {
        padding-top: 0;
    }

    .modal-header {
        padding: 0.5rem 1rem;
    }
}
.location-single label.error{position: relative;}
.one-post{margin-bottom:30px;}
.one-post-desc{padding:24px;}
.one-post-inner{height:266px;width:260px;border-radius:3px;background-color:#FFFFFF;box-shadow:0 0 10px 0 rgba(0,0,0,0.15);}
.one-city-img{width:170px;height:140px;}
.posts-images{width:100%;}
.hide-icon{display:none !important;}
.show-icon{display:block !important;}
.alla-orter-row{padding-top:30px;margin-right:-15px;}
.posts-row{}
#select-style{color:#616161;font-size:16px;line-height:24px;padding-left:16px;height:47px;}
.row-services{padding-top:96px;padding-bottom:43px;}
#menu-main-menu-mob .container{display:flex;align-items:center;justify-content:space-between;position:relative;}
.platser-filter-wrapper{float:right;padding-right:27px;position:relative;}
#mob{display:none;}
@media (min-width:1px) and (max-width:375px){.two-cards .card-item-row-wrapper{width:100% !important;}
}
@media (min-width:1px) and (max-width:575px){.main-image{height:220px;}
    .row3-wrapper{padding-left:20px;padding-right:20px;}
    .row3-left-column{margin-left:10px;margin-right:10px;}
    .row4-column{padding-right:10px !important;}
    .text-center-left{text-align:left;padding-left:22px;}
    .team-image{height:194px;max-width:344px;}
    .second-comment{display:none;}
    .row5-right-inner-col1-wrapper{padding-left:0;}
    .row5-right-inner-col2-wrapper{padding-left:23px;}
    .row5{padding-top:50px;padding-bottom:0;}
    .row5-2{width:344px;margin-left:auto;margin-right:auto;}
    .row5-left-column{width:344px;margin-left:auto;margin-right:auto;}
    .row6-column{padding-left:15px !important;padding-right:15px !important;padding-bottom:23px;}
    .row8-locations .one-location:nth-child(5),
    .row8-locations .one-location:nth-child(6),
    .row8-locations .one-location:nth-child(7),
    .row8-locations .one-location:nth-child(8){display:none;}
    .row8 .card{max-width:100%;}
    .row9-inner-column{padding-left:10px;padding-right:10px;text-align:center;}
    .to-hide,
    .hide-less-768,
    .hide-less-576{display:none;}
    .show-less-576,
    .show-less-768{display:block !important;}
    .row11-image{height:370px;}
    .row11-inner-column{padding:29px 15px 50px;}
    .btn-lg-custom{width:100%;}
    .section-to-show{display:block;}
    .section-to-hide{display:none;}
    .burrial-title{font-size:20px;line-height:24px;padding-top:16px;}
    .our-mission-wrapper{padding-top:0;}
    .our-mission-wrapper .h2{font-size:16px;}
    .our-mission-wrapper h2{font-size:32px !important;}
    .about-fenix-column-inner.about{padding-top:0;}
    .organiser-wrapper{position:relative;}
    .organiser-wrapper{position:relative;top:0;right:0;margin:-64px auto 0;}
    .container.org{padding-left:0 !important;padding-right:0;}
    .last-to-show{display:block;}
    .last-to-hide{display:none;}
    .posts-list-description{padding-right:0;padding-left:0;}
    .posts-list-description > p{margin-bottom:0;}
    /*aside#secondary, .pagination-row{display:none;} */
    .load-more-button-wrapeer{margin-bottom:50px;}
    .blog-title-to-hide{display:none;}
    .header-row{padding-top:22px;}
    .about-us-page .row2{padding-top:36px;padding-bottom:20px;}
    .workers-row{padding-left:3px;}
    .title-col{padding-bottom:25px;}
    .about-fenix-row-wrapper.workers{padding-top:50px;}
    .cards-row .card{width:345px;margin-bottom:25px;}
    .about-fenix-row.why{padding-bottom:50px;}
    .about-fenix-row-wrapper.why{padding-top:50px;padding-bottom:25px;}
    .about-fenix-row.about{padding-top:10px;}
    .row6-column-fullwidth h3,
    .contacts-wrapper h4,
    .memory-notification-wrapper h4{font-size:20px;line-height:24px;}
    .about-fenix-column-inner.why,
    .our-mission-wrapper{padding:0;}
    .about-fenix-image{width:345px;height:214px;}
    .row6-column-fullwidth-inner{padding-left:70px;padding-right:70px;}
    .about-us-page .row6-column-fullwidth{padding-top:50px;padding-bottom:30px;}
    .thank-you-page .row2{padding-top:14px;padding-bottom:14px;}
    .burrial-row-wrapper{padding-top:14px;padding-bottom:30px;}
    .contacts-wrapper{padding-top:0;}
    .rsvp-buttons-wrapper button.btn-outline-primary{padding-left:45px;padding-right:45px;}
    .rsvp-buttons-wrapper button.btn-primary{padding-left:40px;padding-right:40px;}
    .second-check-wrapper{padding-top:20px;}
    .rsvp-buttons-wrapper button.btn{width:100%;}
    .rsvp-buttons-wrapper{padding-top:25px;}
    .memory-notification-wrapper{padding-top:34px;padding-bottom:50px;}
    .rsvp-page .row2{padding-top:14px;padding-bottom:14px;}
    .memory-notification-wrapper h4{text-align:left;}
    form#rsvp .inputs-row{padding-top:14px;padding-bottom:17px;}
    form#rsvp .form-group{margin-bottom:4px;}
    .prod-image-wrapper{width:158px;height:158px;padding:15px;}
    .prod-image{width:127px;height:127px;}
    .product-item-inner{width:158px;}
    .prod-icon{left:124px;}
    .products-row{margin-right:0}
    .product-item-column {flex: 0 0 158px !important;}
    .prod-buttons-row{margin-right:0;}
    .products-pagination-row{height:auto !important;}
    .locations-row{}
    .location-link{width:157px;}
    .loca-image-wrapper{width:157px;height:157px;}
    .loca-image{width:157px;height:157px;}
    .locations-row{margin-right:-10px;}
    #prod-container{padding-left:7px !important;padding-right:0 !important;}
    .prod-buttons-row-wrapper{padding-right:5px;}
    .locations-select{width:80px;}
    .single-location-image{height:224px;}
    .location-link{width:157px;}
    .location-wrapper{width:100%;}
    .card-item-row{margin-right:0;}
    .card-item-row-wrapper{width:auto;}
    .cards-pages .title-inner{padding-bottom:0;}
    .card-item, .card-item-list {width:242px;padding-top:50px;margin-left:auto;margin-right:auto;}
    .card-item-list .card-links {padding: 0 30px;}
    .two-cards .card-item-row{margin-right:0;}
    .two-cards .card-item-row-wrapper{width:auto;}
    #navToHide{display:none;}
    .modal-dialog{max-width:95%!important;margin-left:auto;margin-right:auto;}
    .modal.contact-form{padding-right:0;}
    .row3-right-button-wrapper{padding-top:0;}
    .container-landing .row3{padding-bottom:50px;}
    .organiser-in-post-wrapper #org-first-div{margin-left:0;margin-right:0;}
    .organiser-in-post-wrapper .help-ico-text,
    .organiser-in-post-wrapper .help-ico-text2{right:36px;}
    #swiper-container{display:block;}
    .container-scroll{display:none;}
    .one-city-img{width:160px;height:132px;}
    .row-services{padding-top:50px;padding-bottom:0;}
    .home-row6-column-fullwidth{padding-top:50px;padding-bottom:36px;}
    .home-row8{padding-bottom:0px;}
    .row6-wrapper{padding-bottom:0px;}
    .one-post-inner{margin-left:auto;margin-right:auto;}
    .all-articles-button-wrapper{margin-top:6px;margin-bottom:0;}
    .hide-hover{display:none !important;}
    .prod-buttons-row{margin-right:-10px;}
    .prod-buttons-row .col-9{padding-left:5px !important;}
    .platser-filter-wrapper{padding-right:7px;}
    #mob{display:block;}
    .product-list .products-row .product-item-column:last-child{margin-right: 0!important;}
}
@media (min-width:576px) and (max-width:767px){.main-image,
.home-bottom-image,
.aboutus-bottom-image{height:220px;}
    .row11-inner-column{padding-top:20px;padding-left:0;max-width:540px;margin:0 auto;}
    .home-bottom-image .row11-inner-column{padding-top:40px;}
    .row11-inner-column h2{width:60%;}
    .row4-column{padding-left:10px !important;padding-right:10px !important;}
    .text-center-left{text-align:left;}
    .team-image{height:291px;}
    .second-comment{display:none;}
    .row5-right-inner-col1-wrapper{padding-left:0;}
    .row5-right-inner-col2-wrapper{padding-left:23px;}
    .row5-right-column{padding-bottom:0;}
    .row5{padding-bottom:50px;}
    .row6-column{padding-left:15px !important;padding-right:15px !important;padding-bottom:23px;}
    .row8-locations .one-location:nth-child(3),
    .row8-locations .one-location:nth-child(4),
    .row8-locations .one-location:nth-child(5),
    .row8-locations .one-location:nth-child(6),
    .row8-locations .one-location:nth-child(7),
    .row8-locations .one-location:nth-child(8){display:none;}
    .row9-wrapper .row9-inner-column:nth-child(2),
    .row9-wrapper .row9-inner-column:nth-child(3){display:none;}
    .row9-inner-column{padding-left:10px;padding-right:10px;text-align:center;}
    .section-to-show{display:block;}
    .section-to-hide{display:none;}
    .organiser-wrapper{position:relative;top:0;right:0;margin:-64px auto 0;}
    .to-hide,
    .hide-less-768{display:none;}
    .show-less-768{display:block !important;}
    .posts-list-description{padding-right:0;padding-left:0;}
    .posts-list-description > p{margin-bottom:0;}
    aside#secondary, .pagination-row{display:none;}
    .load-more-button-wrapeer{margin-bottom:50px;}
    .blog-title-to-hide{display:none;}
    .header-row{padding-top:22px;}
    .about-us-page .row2{padding-top:36px;padding-bottom:20px;}
    .our-mission-wrapper{padding-top:0;}
    .our-mission-wrapper .h2{font-size:16px;}
    .our-mission-wrapper h2{font-size:32px !important;}
    .about-fenix-column-inner.about{padding-top:0;}
    .cards-row .card{margin-bottom:25px;width:345px;}
    .about-fenix-row.why{padding-bottom:50px;}
    .about-fenix-row-wrapper.why{padding-bottom:25px;}
    .about-fenix-column-inner.why{padding:0;}
    .rsvp-buttons-wrapper button.btn{width:100%;}
    .second-check-wrapper{padding-top:20px;}
    .rsvp-buttons-wrapper{padding-top:25px;}
    .prod-image-wrapper{width:158px;height:158px;padding:15px;}
    .prod-image{width:127px;height:127px;}
    .product-item-inner{width:158px;}
    .prod-icon{left:124px;}
    .products-row{margin-right:-28px;}
    .single-description-row{padding-top:30px;}
    .single-price-button-wrapper{width:100%;margin-top:16px;}
    .single-quantity-wrapper,
    .single-prod-dropdown,
    .single-price-button-wrapper{width:100%;}
    section.single-product .container.content-column{padding-right:0;}
    .single-prod-image-wrapper{width:100%;height:520px;min-height:346px;padding:45px;}
    .single-prod-image{background-size:contain;background-position:center center;background-repeat:no-repeat;width:100%;height:430px;min-height:274px;}
    .single-prod-wrapper{width:100%;}
    .single-product-item-inner{width:100%;}
    .single-description-row{padding-top:30px;}
    .single-similar-row{padding-bottom:0;}
    .locations-row{}
    .loca-image{width:157px;height:157px;}
    .single-location-image{height:224px;}
    .card-item-row{margin-right:0;}
    .two-cards .card-item-row-wrapper{max-width:550px;width: 100%;}
    .cards-pages .title-inner{padding-bottom:0;}
    .card-item, .card-item-list{width:242px;padding-top:50px;margin-left:auto;margin-right:auto;}
    .two-cards .card-item-row{margin-right:0;}
    #navToHide{display:none;}
    .filter-col-inner{width:83%;}
    .organiser-in-post-wrapper .help-ico-text,
    .organiser-in-post-wrapper .help-ico-text2{right:120px;}
    #swiper-container{display:block;}
    .container-scroll{display:none;}
    .hide-hover{display:none !important;}
    .modal-open #whitediv{position:relative;}
    #mob{display:block;}
}
@media (min-width:768px) and (max-width:991px){.main-image,
.home-bottom-image,
.aboutus-bottom-image{height:340px;}
    .row11-inner-column{padding-top:150px;}
    .row3-left-column{}
    .row4-column{padding-left:20px !important;padding-right:20px !important;}
    .text-center-left{text-align:center;}
    .team-image{height:195px;}
    .second-comment{display:none;}
    .row5-right-inner-col1-wrapper{padding-left:23px;}
    .row5-right-inner-col2-wrapper{padding-left:0;}
    .row5{padding-bottom:80px;}
    .row6-column{padding-left:15px !important;padding-right:15px !important;padding-bottom:23px;}
    .row8-locations .one-location:nth-child(5),
    .row8-locations .one-location:nth-child(6),
    .row8-locations .one-location:nth-child(7),
    .row8-locations .one-location:nth-child(8){display:none;}
    .row9-inner-column{padding-left:20px;padding-right:20px;text-align:center;}
    .section-to-show{display:none;}
    .organiser-wrapper{position:absolute;top:-309px;right:20px;z-index:10;}
    .post-image{width:100%;}
    .posts-list-description{padding-left:0;}
    .posts-list-wrapper{margin-bottom:18px;}
    .secondary-inner-column{width:265px !important;}
    .posts-list-description{padding-right:0;}
    .load-more-button-wrapeer{}
    .blog-title-to-show{display:none;}
    .products-row{margin-right:-30px;}
    .cards-row .card{width:345px;margin-bottom:25px;}
    .about-fenix-row.why{padding-bottom:50px;}
    .about-fenix-row-wrapper.why{padding-bottom:25px;}
    .about-fenix-column-inner.why{padding:0 75px;}
    .our-mission-wrapper{padding-top:45px;padding-left:0;}
    .about-fenix-column-inner.about{padding-top:0;}
    .rsvp-buttons-wrapper button.btn{width:100%;}
    .second-check-wrapper{padding-top:20px;}
    .single-price-button-wrapper{width:100%;margin-top:16px;}
    .single-quantity-wrapper,
    .single-prod-dropdown,
    .single-price-button-wrapper{width:100%;}
    section.single-product{margin-right:-20px;}
    .single-prod-image-wrapper{width:100%;height:398px;min-height:346px;padding:36px;}
    .single-prod-image{background-size:contain;background-position:center center;background-repeat:no-repeat;width:100%;height:326px;min-height:274px;}
    .single-prod-wrapper{width:100%;}
    .single-product-item-inner{width:100%;}
    .single-description-row{padding-top:30px;}
    .single-similar-row{padding-bottom:0;}
    .locations-row{margin-right:-69px;}
    .locations-select{width:80px;}
    .single-location-map{background-size:cover;}
    .single-location-image{height:326px;}
    .card-item-row-wrapper{width:700px;}
    .two-cards .card-item-row{margin-right:-10px;}
    .card-item{width:225px;}
    .card-item .card{padding-left:20px;padding-right:20px;}
    #navToHide{display:none;}
    .filter-col-inner{width:70%;}
    .organiser-in-post-wrapper .help-ico-text,
    .organiser-in-post-wrapper .help-ico-text2{right:65px;}
    #swiper-container{display:block;}
    .container-scroll{display:none;}
    .alla-orter-row{padding-left:46px;}
}
@media (min-width:992px) and (max-width:1199px){.navbar-expand-lg .navbar-nav .nav-link{padding-right:0;padding-left:0;}
    .main-image,
    .home-bottom-image,
    .aboutus-bottom-image{height:430px;}
    .row3-left-column{}
    .row4-column{padding-left:40px !important;padding-right:40px !important;}
    .team-image{height:262px;}
    .row5-right-inner-col1-wrapper{padding-left:23px;}
    .row5-right-inner-col2-wrapper{padding-left:0;}
    .second-comment{display:block;}
    .row5-right-column{padding-bottom:0;}
    .row6-column{padding-left:15px !important;padding-right:15px !important;padding-bottom:23px;}
    .row8-locations .one-location:nth-child(7),
    .row8-locations .one-location:nth-child(8){display:none;}
    .row9-inner-column{padding-left:40px;padding-right:40px;text-align:center;}
    .section-to-show{display:none;}
    .post-image{width:168px !important;}
    .secondary-inner-column{width:265px !important;}
    .load-more-button-wrapeer{}
    .blog-title-to-show{display:none;}
    .products-row{margin-right:-27px;}
    .prod-buttons-row{margin-right:10px;}
    .cards-row .card{width:290px;}
    .about-fenix-column-inner.why{padding-right:50px;}
    .about-fenix-column-inner.about{padding-left:50px;}
    .steps-image-wrapper{padding-top:35px;}
    section.single-product{margin-right:-15px;}
    .locations-row{margin-right:-64px;}
    .organiser-in-post-wrapper .help-ico-text,
    .organiser-in-post-wrapper .help-ico-text2{right:175px;}
    .alla-orter-row{padding-left:46px;}
}
@media (min-width:1200px){.navbar-expand-lg .navbar-nav .nav-link{padding-right:1rem;padding-left:1rem;}
    .main-image,
    .home-bottom-image,
    .aboutus-bottom-image{height:430px;}
    .row4-column{padding-left:55px !important;padding-right:55px !important;}
    .text-center-left{text-align:center;}
    .team-image{height:308px;}
    .row5-right-inner-col1-wrapper{padding-left:23px;}
    .row5-right-inner-col2-wrapper{padding-left:0;}
    .second-comment{display:block;}
    .row6-column{padding-left:43px !important;padding-right:44px !important;padding-bottom:23px;}
    .row9-inner-column{padding-left:59px;padding-right:59px;text-align:center;}
    .section-to-show{display:none;}
    .post-image{width:168px !important;}
    .secondary-inner-column{width:265px !important;}
    .load-more-button-wrapeer{}
    .blog-title-to-show{display:none;}
    .organiser-in-post-wrapper .help-ico-text,
    .organiser-in-post-wrapper .help-ico-text2{right:230px;}
}
@media (min-width:1600px){}
@media (max-width:767px){.row3-right-button, .row7-button, .row8-button{width:100%;}
    .row3-right-title{padding-top:50px;}
}
@media (max-width:375px){.products-row{margin-right:0;}
    .single-price-button-wrapper{width:100%;margin-top:16px;}
    .single-quantity-wrapper,
    .single-prod-dropdown,
    .single-price-button-wrapper{width:100%;}
    section.single-product .container.content-column{padding-right:0;}
    .single-prod-image-wrapper{width:100%;height:346px;min-height:346px;padding:45px;}
    .single-prod-image{background-size:contain;background-position:center center;background-repeat:no-repeat;width:100%;height:274px;min-height:274px;}
    .single-prod-wrapper{width:100%;}
    .single-product-item-inner{width:100%;}
    .single-description-row{padding-top:30px;}
    .single-similar-row{padding-bottom:0;}
    .page-id-97 footer#colophon{margin-top:0 !important;}
    .product-item-column{margin-bottom:29px;}
    .two-cards .card-item-row-wrapper{width:auto;}
    .filter-col-inner{width:70%;}
    .product-item-column {flex: 0 0 158px !important;}
}
.order-quantity-chip{font-size:13px;font-weight:bold;background-color:#cecece63;padding:4px 6px;border-radius:4px;margin-left:-25px;display:block;float:left;}
@media (min-width:992px){#masthead .mob-menu-items{display:none;}
}
@media (max-width:991px){.button-cta-link,.mob-menu-items>a{margin-left:0 !important;}
}
@media (max-width:440px){.navbar .button-cta{font-weight:normal !important;font-size:14px;}
}
@media (max-width:415px){.navbar-brand{margin:5px 7px 5px 0;}
    .navbar .button-cta{font-size:12px;}
    .navbar-brand img{width:90px;height:49px;}
}
@media (max-width:320px){.navbar-brand{margin:9px 5px 9px 0;}
    .navbar .button-cta{font-size:10px;}
    .navbar-brand img{width:70px;height:38px;}
}
.noselect,
.funnel-summary,
.funnel-added-product{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
i.fenix-icon:before{margin-left:0;margin-right:0;}
@media (max-width:524px){#page{}
}
@media (max-width:374px){#swiper-container{width:100% !important;}
    .organiser-wrapper,
    .organiser{max-width:100% !important;}
    .row8-locations .location-wrapper .location-img{width:100% !important;}
}
@media (max-width:320px){.container.org-v2 #org-first-div,
.container.org-v2.v3 #org-first-div,
#swiper-container{width:320px !important;}
    .container.org-v2 #org-first-div{padding-left:24px;padding-right:24px;}
    .container.org-v2.v3 #org-first-div{padding-left:25px;padding-right:25px;}
    .container.org-v2.v3 #organiser .wrap-cta-person:before{font-size:11px !important;}
    #swiper-container .card{padding-left:12px;padding-right:12px;}
    .cards-row .card,
    .card-img-top{width:295px !important;}
    .row8-locations .location-wrapper{width:140px;}
    .row8-locations .location-wrapper .location-img{}
    .row8-locations .location-wrapper .location-img .one-city-img,
    .loca-image-wrapper .loca-image{width:143px !important;}
    .row5-wrapper .row5-2{width:300px !important;}
    .row5-wrapper .row5-right-inner-col2-wrapper{width:275px;}
    .page-id-155 .about-fenix-image{width:300px;}
    .our-mission-wrapper h2{font-size:26px !important;}
    .about-fenix-row-wrapper.workers .container{padding-right:5px;padding-left:5px;}
    .workers-row{padding-left:0;}
    .workers-row .card-block.pl-3{padding-left:3px !important;}
    .locations-pagination-row{overflow-x:hidden;}
    .location-landing .prod-buttons-row .wrap-dropdown{width:160px;}
    .locations-row .one-location:last-child, .locations-row .fake-location:last-child, .products-row .product-item-column:last-child .funnel-prodcats-row .product-item-column:last-child, .all-cities .alla-orter-row .one-location:last-child, .row8-locations .one-location:last-child {margin-right: 0 !important;}
}
@media (max-width:1199px) and (min-width:1px){.row.no-gutters.justify-content-between .one-post-inner{width:auto;max-width:260px;}
}
@media (max-width:767px) and (min-width:576px){.home .one-post-inner{margin:auto;}}
@media (max-width:1199px) and (min-width:1px){.location-wrapper{margin:0 auto 20px;}
}
@media (max-width:767px) and (min-width:576px){#mobNavSearchButton:after{bottom:-23px !important;}}
@media (max-width:991px) and (min-width:576px){#whitediv{top:78px;}}
#whitediv{z-index:9999;}
i.close-search-result.fenix-icon.icon-cancel{top:0;}
@media (max-width:575px){i.close-search-result.fenix-icon.icon-cancel{top:16px;}
}
#searchToShow input#searchField::-ms-clear{display:none;}
.justify-content.no-gutters.row.card-item-row .card-item-button-wrapper.mx-auto{text-align:center;}
i.fenix-icon{transition:color 0.15s ease-in-out;}
.btn.btn-primary{background-color:#4C84F5;border-color:#4C84F5;}
.btn.btn-primary:hover{background-color:#0069D9;border-color:#0069D9;}
.btn-primary:not(:disabled):not(.disabled):active{background-color:#004D9F;}
.btn.btn-outline-primary{font-weight:600;color:#002733;background-color:#fff;border:1px solid #002733;}
.btn.btn-outline-primary:not([disabled]):hover{color:#002733c2;background-color:#fff;border:1px solid #002733c2;}
.btn.btn-outline-primary:not(:disabled):not(.disabled):active{color:#004D9F;background-color:#F1F1F1;border:1px solid #004D9F;}
ul.pagination{font-weight:600;}
ul.pagination .page-item.active .page-link{background-color:#002733;}
.navbar-light .navbar-nav .nav-link{color:#616161;}
.navbar-light .navbar-nav .active .nav-link,
.navbar-light .navbar-nav .nav-link.active{color:#37373C;font-weight:bold;border-bottom:4px solid #4A90E2;}
.navbar-light .navbar-nav .nav-link:hover{color:#37373C;}
.navbar-nav .fenix-icon{color:#616161;}
.fenix-icon.icon-search{font-weight:bold;}
@media (min-width:992px){.navbar-expand-lg .navbar-nav .nav-link{padding-top:23px;padding-bottom:19px;}
}
.fenix-icon.icon-search:hover,
.fenix-icon.icon-cart:hover,
.fenix-icon.icon-clipboard:hover{color:#37373C !important;}
.fenix-icon.icon-cancel{font-size:22px;}
.fenix-icon.hide-cart{display:none;}
#citySelectorDropdown{background-color:#464646;color:#fff;border:0;}
#topbar-phone-number{color:#929292;font-size:11px;font-weight:600;letter-spacing:0.8px;line-height:13px;text-align:right;position:relative;}
#topbar-phone-number a span.telnum{color:#616161;font-size:16px;font-weight:bold;line-height:19px;text-align:right;}
#topbar-phone-number i.icon-phone{font-size:22px;color:#bbb;}
#topbar-phone-number a:hover{text-decoration:none;}
#topbar-phone-number:hover a span.telnum{text-decoration:underline;}
#topbar-phone-number:not(.funnel-special) a:hover i.icon-phone{color:#fff;}
#topbar-phone-number.funnel-special:hover i.icon-phone{color:#616161;}
#topbar-phone-number:not(.funnel-special):hover a span.telnum{text-decoration-color:#fff;}
#topbar-phone-number:hover a span.telnum{text-decoration-color:#616161;}
#topbar-city-dropdown i.icon-location,
#locationCheckForm i.icon-location{font-size:24px;color:#bbb;position:absolute;left:-15px;top:6px;z-index:5;}
@media (max-width:320px){#locationCheckForm i.icon-location{left:-15px !important;}
}
#topbar-city-dropdown i.icon-location:hover{color:#616161;}
.badge{margin-right:5px;}
.form-control.city-selector-dropdown:focus{box-shadow:none;}
#topbar-city-dropdown .form-control.city-selector-dropdown{-moz-appearance:none;-webkit-appearance:none;appearance:none;background:#464646 url('/wp-content/themes/fenix4/images/down-arrow-white.png') 95% no-repeat;}
#topbar-city-dropdown .bootstrap-select.city-selector-dropdown .dropdown-toggle .filter-option{color:#fff;background-color:#464646;background:#464646 url(/wp-content/themes/fenix4/images/down-arrow-white.png) 95% no-repeat;}
.bootstrap-select.city-selector-dropdown .dropdown-toggle .filter-option:focus{outline:0 !important;background:none !important;border:0 !important;}
.show > .btn-light.dropdown-toggle:hover,
.show > .btn-light.dropdown-toggle:focus{box-shadow:none !important;background:none !important;border:0 !important;}
.bootstrap-select.city-selector-dropdown .dropdown-toggle{border:0;}
.bootstrap-select.city-selector-dropdown .dropdown-toggle::after{}
.show > .btn-light .dropdown-toggle,
.show > .btn-light .dropdown-toggle:focus{outline:0 !important;background:none !important;border:0 !important;}
.contacts-wrapper .dropdown-menu.show{z-index:1200;}
#wrap-mob-nav-city-dropdown{position:relative;width:95%;padding-left:10%;margin-top:10px;margin-bottom:30px;}
.wrap-cta i.fenix-icon{font-size:24px;color:#bbb;position:absolute;right:91%;top:6px;}
#wrap-mob-nav-telnum i.icon-phone{top:20px;}
#menu-main-menu-mob .button-cta-link,
#menu-main-menu-mob .button-telnum{display:block;position:relative;width:95%;padding-left:10%;}
#menu-main-menu-mob .button-cta{width:100%;}
.wrap-cta{position:relative;margin-top:20px;}
#mob-navbar-phone-icon-sec .fenix-icon.icon-phone,
#mob-navbar-search-icon-sec{position:relative;top:-7px;}
#mob-navbar-phone-icon-sec .fenix-icon.icon-phone:after,
button#collapseButton:after,
button#collapseButtonClose:after,
#mobNavSearchButton:after,
#mob-navbar-search-icon-sec:after,
#searchSwitcher:after,
#navCartIconWrapper:after{font-family:"rawline", sans-serif;font-size:12px;position:absolute;font-weight:bold;}
#mob-navbar-phone-icon-sec .fenix-icon.icon-phone:after{/*content:"Ring";*/left:3px;bottom:-26px;color:rgba(0, 0, 0, 0.5);}
button#collapseButton,
button#collapseButtonClose{position:relative;top:-5px;}
button#collapseButton:after,
button#collapseButtonClose:after{/*content:"Meny";*/left:11px;bottom:-13px;}
#mobNavSearchButton:after,
#mob-navbar-search-icon-sec:after{content:"Sök";color:rgba(0, 0, 0, 0.5);}
#mob-navbar-search-icon-sec:after{left:5px;bottom:-26px;}
#mobNavSearchButton:after{left:9px;bottom:-28px;}
#navbarNavAltMarkup.hidden{display:none !important;}
.page-id-122 #topbar{display:none;}
.breadcrumb-item.hidden{display:none;}
ol.breadcrumb.container{display:-webkit-flex;}
i.fenix-icon.icon-filter{color:#4C84F5;font-size:22px;position:absolute;left:22px;top:6px;}
.locations-row .one-location:last-child,
.locations-row .fake-location:last-child,
.products-row .product-item-column:last-child,
.funnel-prodcats-row .product-item-column:last-child,
.alla-orter-row .one-location:last-child,
.row8-locations .one-location:last-child{margin-right:auto !important;}
#funnel-single-product-category,
#funnel-single-product{position:relative;}
#funnel-single-product-category.container:before,
#funnel-single-product.container:before{content:'';position:absolute;display:block;width:calc(100% + 50px);height:calc(100% + 50px);top:-25px;left:-25px;border-radius:3px;box-shadow:0 0 10px 0 rgba(0,0,0,0.15);visibility:hidden;opacity:0;transition:visibility .3s ease-in-out, opacity .3s ease-in-out;}
#funnel-single-product-category.container.close-hover:before,
#funnel-single-product.container.close-hover:before{visibility:visible;opacity:1;}
#funnel-single-product-category h5,
#funnel-single-product h5{padding-right:30px;}
.funnel-close{position:absolute;right:0;font-size:34px;font-weight:bold;color:#616161;cursor:pointer;top:-10px;}
.funnel-close .fenix-icon.icon-cancel{font-size:34px;color:#fff;background-color:#616161;border-radius:50%;position:relative;}
.funnel-close .fenix-icon.icon-cancel:after{/*content:'Tillbaka';*/color:#616161;font-size:16px;line-height:24px;font-weight:400;font-family:'rawline', sans-serif;position:absolute;bottom:-24px;left:50%;transform:translateX(-50%);}
.funnel-close.step3{right:-10px;}
.just-added{box-shadow:0 0 0 0.2rem rgba(0,123,255,.5) !important;}
.funnel-added-product{transition:box-shadow 1.5s ease-out;}
.funnel-added-product.just-added{transition:box-shadow .3s ease-in;}
#funnelClientDetails label.error{margin-top:0;}
#funnelClientDetails .form-control{height:48px;}
.funnel_right .wrap-award{margin-bottom:95px !important;}
#WrapFunnelCart{position:relative;}
#btnFunnelHelp{position:absolute;top:-8px;right:0;}
.funnel .header-row{padding-top:0;}
form#funnelClientDetails input.form-control.error{border:1px solid red;background-color:#ffe2e0;}
form#funnelClientDetails label.error{position: relative;}
form#funnelClientDetails .form-group{margin-bottom:0;}
form#funnelClientDetails input.form-control.error::placeholder{color:#616161 !important;}
form#funnelClientDetails input.form-control.error::-webkit-input-placeholder{color:#616161 !important;}
form#funnelClientDetails input.form-control.error::-moz-placeholder{color:#616161 !important;}
form#funnelClientDetails input.form-control.error:-ms-input-placeholder{color:#616161 !important;}
form#funnelClientDetails input.form-control.error:-moz-placeholder{color:#616161 !important;}
.modal i.icon-phone{color:#bbb;font-size:24px;}
a.hjalp-telnum:hover{text-decoration-line:none;}
a.hjalp-telnum:hover > i.icon-phone{color:#37373C;}
a.hjalp-telnum:hover span{text-decoration:underline !important;text-decoration-color:#37373C;}
.funnel-hjalp-popup h6{margin-bottom:.2rem;}
.funnel-hjalp-popup .modal-footer{justify-content:flex-start;}
.funnel-added-product .fenix-icon.icon-trash-alt-regular{position:relative;top:-1px;}
.funnel-added-product .fenix-icon.icon-trash-alt-regular:after{/*content:'Ta bort';*/display:block;width:44px;height:10px;position:absolute;top:-17px;left:-16px;font-family:rawline, sans-serif;font-size:10px;font-weight:700;text-transform:uppercase;visibility:hidden;opacity:0;transition:visibility .15s ease-in-out, opacity .15s ease-in-out;}
.funnel-added-product .fenix-icon.icon-trash-alt-regular:hover:after{visibility:visible;opacity:1;}
.modal{z-index:1075;}
.site-footer a:hover{text-decoration-color:#fff;}
.site-footer .locations-select{width:130px;color:#fff;-moz-appearance:none;-webkit-appearance:none;appearance:none;background:#464646 url(/wp-content/themes/fenix4/images/down-arrow-white.png) 95% no-repeat;font-size:14px;font-weight:400;}
input::placeholder{color:#ced4da !important;}
input::-webkit-input-placeholder{color:#ced4da !important;}
input::-moz-placeholder{color:#ced4da !important;}
input:-ms-input-placeholder{color:#ced4da !important;}
input:-moz-placeholder{color:#ced4da !important;}
.contact-form .form-control:focus{color:#616161 !important;box-shadow: unset;}
input[type=date]:invalid::-webkit-datetime-edit{color:#ced4da !important;}

@media (min-width:768px) and (max-width:991px){.home .section-to-hide.lp-heading{margin-bottom:30px;}
}
.home-services-image{display:block;margin:0 auto 10px;}
.home-bottom-image{position:relative;background:url('/wp-content/themes/fenix4/images/city-bottom-banner-1.jpg') top center/cover no-repeat;}
.home-bottom-image:before{content:'';display:block;position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(65,63,67,0.6);}
.home .wrap-begravningspaket{background-color:#F5F5F5;padding-bottom:38px;}
.home .wrap-begravningspaket .swiper-slide{background-color:transparent;}
@media (min-width:992px) and (max-width:1199px){.wrap-begravningspaket.four .begravningspaket.no-gutters [class*=col-]:nth-child(1),
.wrap-begravningspaket.four .begravningspaket.no-gutters [class*=col-]:nth-child(2){margin-bottom:30px;}
}
@media (max-width:1199px){body:not(.home) .begravningspaket .col-4:after{content:'';display:block;width:194px;height:194px;position:absolute;border-radius:50%;background-color:rgba(0,0,0,0.03);z-index:-5;}
}
.begravningspaket .col-4:nth-child(1):after{bottom:-60px;right:-70px;}
.begravningspaket .col-4:nth-child(2):after{top:-25px;left:-65px;}
.begravningspaket .col-4:nth-child(3):after{bottom:35px;left:-85px;}
.begravningspaket .col-4:nth-child(4):after{top:100px;}
.overview-3-cards .col-12:nth-child(1):after,
.overview-3-cards .col-12:nth-child(2):before,
.overview-3-cards .col-12:nth-child(2):after,
.overview-2-cards .col-12:nth-child(2):before,
.overview-2-cards .col-12:nth-child(2):after{content:'';display:block;width:150px;height:150px;position:absolute;border-radius:50%;background-color:rgba(0,0,0,0.03);z-index:-5;}
.overview-3-cards .col-12:nth-child(1):after{top:135px;right:-30px;}
.overview-3-cards .col-12:nth-child(2):before{top:0px;right:25px;}
.overview-3-cards .col-12:nth-child(2):after{bottom:220px;right:25px;}
.overview-2-cards .col-12:nth-child(2):before{top:0;left:-10px;}
.overview-2-cards .col-12:nth-child(2):after{bottom:220px;left:-10px;}
/*.about-us-page .main-image{background-image:url('/wp-content/themes/fenix4/images/about-header-banner-2.jpg');background-position:right;}*/
/*.about-us-page .row11-image{background:url('/wp-content/themes/fenix4/images/about-bottom-banner-1-big.jpg') bottom right/cover no-repeat;}*/
.about-us-page .row11-image{background-size: cover;background-position: bottom right;background-repeat: no-repeat;}
.blog #filter-row{margin-right:-3px;}
a.reco-link img{transition:margin 700ms;}
a.reco-link:hover{text-decoration:none;}
.wrap-award.reco-2018 img:not(:first-of-type){margin-left:-150px;}
a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(4){margin-left:-200px;}
a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(3){margin-left:-200px;}
a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(2){margin-left:-200px;}
a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(1){margin-left:0;}
.reco-logo{position:absolute;right:20px;bottom:15px;}
.one-reco-review h6{margin-bottom:8px;}
.reco-stars{display:inline-block;float:right;margin-right:50px;}
@media (min-width:768px){.reco-review-text{min-height:145px;}
}
@media (max-width:380px){a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(4){margin-left:-190px;}
    a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(3){margin-left:-190px;}
    a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(2){margin-left:-190px;}
    a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(1){margin-left:0;}
}
@media (max-width:991px){.about-fenix-column-inner{margin-bottom:16px;}
    .hidden-on-less-than-992px{display:none;}
    .shown-on-less-than-992px{display:block !important;}
}
.card-icon-svg{width:70px;height:66px;margin-bottom:40px;}
.card-icon-svg img{display:block;margin:0 auto;}
.mega-content .card-icon-svg{margin-bottom:10px;}
.prodcats-link-wrapper{width:170px;}
.prodcat-img img{border:1px solid #dee2e6;}
.funnel-prodcats-row .prod-image-wrapper.selected:before{content:'';display:block;width:20px;height:20px;background-color:#4A90E2;border-radius:50%;position:absolute;top:-10px;left:-10px;}
.funnel-single-prod-dropdown{width:170px;height:44px;}
.funnel-single-quantity-wrapper{width:170px;height:44px;border:1px solid rgba(39,53,56,0.1);border-radius:3px;}
.funnel-single-quantity-number{height:42px;color:#4C84F5;font-size:16px;font-weight:600;line-height:42px;border-left:1px solid rgba(39,53,56,0.1);border-right:1px solid rgba(39,53,56,0.1);}
input.single-quantity-number[type=number]::-webkit-inner-spin-button,
input.single-quantity-number[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;margin:0;}
.funnel-single-quantity-button-wrapper button span{color:#4C84F5;font-weight:bold;}
table.funnel-summary td{border-width:2px;}
.funnel-added-product{background-color:#fff;height:72px;box-shadow:5px 5px 5px 0 rgba(0,0,0,0.1);cursor:pointer;}
#funnelCartSummaryAlwaysIncludedCollapsedWrap,
#funnelCartSummaryAlwaysIncludedWrap{box-shadow:5px 5px 5px 0 rgba(0,0,0,0.1);}
tr#funnel-dummy-tr-to-show td{padding:3px;}
.funnel-added-product:hover{background-color:rgba(0,0,0,.075);}
.product-checked{background-color:rgba(0,0,0,.075);}
.product-checked:hover{background-color:#fff;}
tr.funnel-added-product td{vertical-align:middle;}
tr.funnel-added-product td:first-of-type{max-width:280px;}
td.funnel-added-product-title{font-weight:bold;}
.funnel-added-prod-image-wrapper{display:block;padding:4px;border:2px solid rgba(39,53,56,0.1);margin-right:14px;width:48px;float:left;}
.funnel-added-prod-image-wrapper-flex{display:block;padding:4px;border:2px solid rgba(39,53,56,0.1);margin-right:14px;width:48px;height:48px;}
.funnel-added-prod-title{width:175px;line-height:24px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.funnel-added-prod-var-title{width:175px;font-size:14px;font-weight:400;line-height:20px;word-break:break-word;-webkit-hyphens:auto;-moz-hyphens:auto;-ms-hyphens:auto;-o-hyphens:auto;hyphens:auto;}
.funnel-added-prod-title-flex{width:180px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.input-qty{width:30px;border:none;}
.qty-buttons-wrapper{height:100%;width:30px;}
#funnel-side table tr td:last-child{text-align:right;vertical-align:middle;}
.funnel_left{padding-bottom:60px;}
.funnel_left .prod-image-wrapper{position: relative;}
.funnel_left .prod-image{}
.funnel_left .prod-desc{min-height:80px;}
.fun-drop-down .fenix-icon{font-size:22px;transition:transform 150ms ease;}
.fun-drop-down i.fenix-icon.icon-right.open{transform:rotate(90deg);}
@media (max-width:320px){.prod-wrapper{width:140px;}
    a:not(.fun-single-product-link) .prod-image-wrapper{width:140px;height:140px;padding:10px;}
    a:not(.fun-single-product-link) .prod-image{width:120px;height:120px;}
    .prod-title{overflow:hidden;text-overflow:ellipsis;}
    .funnel-quick-add{width:90% !important;}
    .prodcat-img img{max-width:145px !important;max-height:145px !important;}
    .prodcats-link-wrapper{width:150px !important;}
}
.icon-cart.filled:after,
.icon-clipboard.filled:after{content:'';display:block;width:15px;height:15px;background-color:#C4D8FF;border-radius:50%;position:absolute;top:8px;left:23px;}
table#funnelCartSummaryAlwaysIncludedCollapsed td,
table#funnelCartSummaryAlwaysIncluded td{background-color:#fff;}
.navbar #navCartIcon{position:relative;}
@media (max-width:767px){.funnel i#navCartIcon{position:absolute;}
    #topbar-phone-number{display:none;}
    #topbar-mob-totalsumma{display:block !important;line-height:18px;padding:10px 0 10px 55px;cursor:pointer;z-index:10;}
    #navCartIcon.icon-cart.mob,
    #navCartIcon.icon-clipboard.mob{visibility:visible;position:relative;padding-top:30px !important;padding-bottom:15px !important;}
    .icon-cart.mob.filled:after,
    .icon-clipboard.mob.filled:after{width:15px;height:15px;top:20px;left:24px;}
    #funnel-side{display:none;}
    #secondary{padding-left:0 !important;padding-right:5px;}
    #WrapFunnelCart > h5,
    #WrapFunnelDetails > h5{padding-top:0 !important;}
    .three-buttons.funnel{display:inline-flex !important;margin-top:10px;}
    .three-buttons.funnel label{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
    .two-buttons.funnel{display:inline-flex !important;margin-top:30px;}
    .two-buttons.funnel label{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
    #navCartIcon.icon-cart.mob,
    #navCartIcon.icon-clipboard.mob{position:absolute;top:-1px;right:220px;z-index:5;}
}
@media (max-width:575px){body.funnel .row-breadcrumbs{display:none;}
    #navCartIcon.icon-cart.mob,
    #navCartIcon.icon-clipboard.mob{position:absolute;right:110px;}
    .products-row .product-item-column:last-child{margin-right: 0!important;}
}
#searchSwitcher{position:relative;}
#searchSwitcher:after{content:"Sök";color:rgba(0, 0, 0, 0.5);left:17px;bottom:12px;}
.navbar #navCartIcon{color:#616161;}
body.funnel #navCartIconWrapper:after{visibility:hidden;}
@media (min-width:992px){body:not(.funnel) .navbar #navCartIcon.filled{visibility:visible;}
    #navCartIconWrapper{position:relative;}
    #navCartIconWrapper:after{/*content:"Sparat";*/color:#fff;left:0;bottom:5px;}
}
#navCartIconWrapper:hover{text-decoration-line:none;}
/*.rsvp-page .main-image{background-image:url('/wp-content/themes/fenix4/images/rsvp-header-banner.jpg');background-position:right;}*/
span.obligatory{font-size:16px;font-weight:bold;color:red;line-height: 15px;}
label#addinfoname-error{top:78px;left:0;}
@media (max-width:991px){#rsvp .inputs-row.row.no-guttres{max-width:100%;margin:0 auto;}
}
#navCartIcon{visibility:hidden;}
.page-id-85 #navCartIcon,
.page-id-66097 #navCartIcon{visibility:visible;}
.wrap-dropdown{width:200px;float:left;margin-right:20px;}
.wrap-dropdown .bootstrap-select .dropdown-toggle .filter-option-inner-inner{font-weight:600;}
.wrap-dropdown .bootstrap-select .dropdown-toggle.btn-light{background-color:#F4F5F7;border:1px solid #CCCCCC;}
a.posts-list-wrapper:hover .posts-list-description h5{text-decoration:underline;}
.alignleft{float:left;margin:0.35em 1.313em 0.35em 0;}
.alignright{float:right;margin:0.35em 0 0.35em 1.313em;}
.cta-in-post-wrapper{position:relative;margin:40px auto;padding:30px 50px;box-shadow:0 0 10px 0 rgba(0,0,0,0.25);}
.cta-in-post-wrapper.cta2{padding:30px 120px;}
.cta-in-post-wrapper .h5,
.cta-in-post-wrapper p{text-align:center;}
.cta-in-post-wrapper .h5{margin-bottom:30px;}
.cta2-button{display:block;width:250px;margin:30px auto 0;text-align:center;}
.cta2-button button{padding-left:60px;padding-right:60px;}
.organiser-in-post-wrapper .organiser{height:480px;}
.organiser-in-post-wrapper h2{text-align:center;font-size:20px;line-height:24px;margin:10px 0 30px;}
.organiser-in-post-wrapper .help-ico-text{top:125px;}
.organiser-in-post-wrapper .help-ico-text2{top:245px;}
@media (min-width:576px){.container.org-v2 #organiser > .h6{font-size:18px;}
}
.container.org-v2 #organiser img.cta-person{border-radius:50%;}
.wrap-cta-person{position:absolute;top:4px;left:20px;}
.container.org-v2 #organiser .wrap-cta-person:after,
.mob-menu-items .button-cta-link.cart-restore:after{content:"";display:block;width:16px;height:16px;top:40px;right:0;position:absolute;border-radius:50%;border:2px #fff solid;background-color:#28a745;}
.mob-menu-items .button-cta-link{position:relative;}
.mob-menu-items .button-cta-link.cart-restore:after{background-color:#4A90E2;width:20px;height:20px;top:-8px;right:-8px;}
.container.org-v2 #organiser .wrap-cta-person.offline:after{background-color:#ffc107;}
.container.org-v2 .wrap-award.reco-2018{margin:0 !important;}
.container.org-v2 .wrap-award.reco-2018 img{width:80px;height:80px;}
.container.org-v2 .wrap-award.reco-2018 img:not(:first-of-type){margin-left:-123px;}
.container.org-v2 a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(4){margin-left:-160px;}
.container.org-v2 a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(3){margin-left:-160px;}
.container.org-v2 a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(2){margin-left:-160px;}
.container.org-v2 a.reco-link:hover .wrap-award.reco-2018 img:nth-of-type(1){margin-left:0;}
.wrap-organizer-for-articles .organiser-wrapper{position:static;margin:20px auto;}
.page-id-155 .wrap-award.reco-2018{margin:50px 0;}
.page-id-155 .address-icons{position:relative;top:2px;}
@media (max-width:400px){.wrap-organizer-for-articles{position:relative;}
    .wrap-organizer-for-articles .organiser-wrapper{position:relative;right:9px;}
    .cta2-button{width:100%;}
    .cta2-button button{padding-left:40px;padding-right:40px;}
}
@media (max-width:370px){.cta2-button button{padding-left:30px;padding-right:30px;}
}
@media (max-width:320px){.organiser-wrapper{right:0px;width:100%;}
    .wrap-organizer-for-articles .organiser-wrapper{right:35px;width:100%;}
    .wrap-organizer-for-articles #reco-rating{margin-left:35px;}
    .author-date{font-size:14px;}
}
@media (min-width:768px){.city-landing.cityvar-1 .container.sec-2{min-height:190px;}
}
.city-landing.cityvar-1 .about-fenix-row-wrapper{padding-top:0;}
.city-landing.cityvar-1 .about-fenix-row.why{padding-bottom:0;}
.city-landing.cityvar-1 .home-image{border-radius:50%;}
.container.org-v2.v3 #org-first-div{height:auto;padding-bottom:25px;}
.container.org-v2.v3 #organiser a,
.container.org-v2.v3 #organiser a p span{font-weight:bold;color:#007bff;transition:color .15s ease-in-out}
.container.org-v2.v3 #organiser a:hover,
.container.org-v2.v3 #organiser a:hover p span{color:#0056b3;}
.organiser-hr.v3{border-top-style:solid;}
.container.org.org-v2.v3 .row3-left-item{margin-bottom:5px;}
.container.org.org-v2.v3 .wrap-cta-person{display:inline-block;position:relative;left:0;text-align:center;}
.container.org-v2.v3 #organiser .wrap-cta-person:after{width:20px;height:20px;top:54px;left:54px;}
.container.org-v2.v3 #organiser .wrap-cta-person:before{font-size:12px;position:absolute;width:170%;left:-35%;bottom:-20px;}
.container.org-v2.v3 #organiser .wrap-cta-person.runius:before{content:'Charlotte Runius';}
.container.org-v2.v3 #organiser .wrap-cta-person.ceder:before{content:'Charlotte Ceder';}
.container.org-v2.v3 #organiser .wrap-cta-person.schneider:before{content:'Alexandra Schneider';}
.container.org-v2.v3 .wrap-award.reco-2018{margin-top:15px !important;}
.container.org-v2.v3 .address-icons{font-size:30px;color:#212529;}
.container.org-v2.v3 .icon-phone-circle.address-icons{font-size:28px;}
.container.org-v2.v3 i.fenix-icon.icon-location-outlines.address-icons{margin-top:10px;}
@media (min-width:576px){.container.org-v2.v3 #organiser > .h6{font-size:20px;line-height:24px;}
}
.city-landing .about-fenix-row-wrapper{background-color:transparent;}
@media (max-width:767px){.city-landing.cityvar-1 .about-fenix-row-wrapper{margin-top:15px;}
    .map-city-offices-wrapper{margin-bottom:40px !important;}
}
@media (max-width:991px){.city-landing.cityvar-1 .cards-row .card.mr-auto,
.city-landing.cityvar-1 .cards-row .card.ml-auto{margin-right:auto !important;margin-left:auto !important;}
    .city-landing.cityvar-1 .row5-wrapper{margin-top:60px;}
}
@media (max-width:575px){.row8 .card{margin-bottom:30px;}
}
.sticky-top{z-index:1070;}
@media all and (min-width:576px) and (max-width:991px){.post-template-default .post-content-wrapper p{word-break:break-word;-webkit-hyphens:auto;-moz-hyphens:auto;-ms-hyphens:auto;-o-hyphens:auto;hyphens:auto;}
}
@media all and (max-width:575px){.post-template-default .post-content-wrapper p > img{float:none;margin:0 auto;margin-bottom:0.35rem;display:block;max-width:100%;}
}
@media all and (min-width:768px) and (max-width:991px){.post-template-default .post-content-wrapper p > img{float:none;margin:0 auto;margin-bottom:0.35rem;display:block;max-width:100%;}
}
@media all and (max-width:991px){.cta-in-post-wrapper.cta2{padding:30px 50px;}
}
footer div.footer-items{padding-right:5%;}
@media all and (min-width:576px) and (max-width:991px){.navbar:not(.nav-funnel) #navCartIcon{display:none;}
}
@media all and (max-width:575px){.product-item-inner{margin:auto;}
}
@media all and (max-width:575px){div#funnel-product-categories-grid .prodcats-link-wrapper{margin:auto;}
}
@media (min-width:1px) and (max-width:575px){.hidden-on-mob{display:none;}
    #topbar{display:none;}
    #mob-navbar-phone-icon{display:block !important;position:absolute;top:17px;}
    #mob-navbar-phone-icon{right:150px;}
    #mob-navbar-search-icon{right:100px;}
    #mob-navbar-phone-icon:hover{text-decoration:none;}
    #mob-navbar-phone-icon:hover i{color:#37373C;}
    #mob-navbar-phone-icon i,
    #mob-navbar-search-icon{font-size:30px;color:#bbb;padding-top:6px;}
    .navbar-nav .fenix-icon.icon-search{display:none;}
    .home-services-image{width:45px;max-height:50px;margin-left:10px;padding-top:0 !important;}
    .home-services-text{padding-left:15px;}
    .funnel_right{margin-top:0 !important;padding-left:5px !important;padding-right:5px !important;}
    .prodcat-img img{max-width:160px;max-height:160px;}
    #funnel-side{background-color:transparent !important;padding-left:0 !important;padding-right:0 !important;}
    #funnel-side .table td{padding-left:0 !important;padding-right:0 !important;}
    #funnel-single-product-category .funnel-prodcats-row{margin-right:-20px;}
    .prodcats-link-wrapper{width:160px;}
    .home-bottom-image{background:url('/wp-content/themes/fenix4/images/city-bottom-banner-1-mob.jpg') top left/cover no-repeat;}
    .home-bottom-image:before{display:none;}
    .about-us-page .row11-image{background:url('/wp-content/themes/fenix4/images/about-bottom-banner-1-mob.jpg') bottom right/cover no-repeat;}
    .about-us-page .row11-inner-column .h4{font-size:20px;}
    #wrapOrgForm {width: 100%!important;}
    aside#secondary {padding-right: 0!important;}
    .one-location {max-width: 160px;}
    .alla-orter-row .one-location:last-child,.locations-row .one-location:last-child{margin-right: 0 !important;}
    .location-img.mx-auto {max-width: 160px;}
    .location-single__nearestlist{padding-right: 0!important;}
}
@media (min-width:576px) and (max-width:767px){.funnel_right{margin-top:0 !important;}
    .prodcat-img img{max-width:160px;max-height:160px;}
    .funnel-prodcats-row{margin-right:-18px;}
}
@media (min-width:768px) and (max-width:991px){.funnel-prodcats-row{margin-right:-89px;}
}
@media (min-width:992px) and (max-width:1199px){.funnel_left{padding-right:30px;}
    .funnel-prodcats-row{margin-right:-89px;}
}
@media (min-width:1200px){.funnel-prodcats-row{margin-right:-20px;}
}
.red{color:red;}
.ebok-landing .main-image{background:#ededf7;}
.ebok-person{background:url('/wp-content/themes/fenix4/images/charlotte_runius_grundare.png') left top/contain no-repeat;position:relative;left:-90px;}
.ebok-landing .lp-heading ul{font-size:28px;font-weight:600;}
.ebok-landing .lp-heading ul li{margin:28px 0;}
.organiser.ebok{width:400px;height:auto;padding:20px 45px;}
.ebok .organiser-wrapper{top:-395px;}
.ebok-reviews-special-color{background-color:#ededf7;padding-top:50px;padding-bottom:50px;}
a.ebok-cta{display:block;height:150px;width:394px;position:relative;top:3px;left:-42px;text-align:center;border:6px solid red;border-radius:50%;}
.ebok-cta .btn{font-size:28px;background-color:red;border-color:red;width:300px;margin:34px auto;}
a.ebok-cta:not(.disabled):hover > .btn, a.ebok-cta:not(.disabled):active > .btn{background-color:#c90505 !important;border-color:red;}
.ebok-cta:after{content:'';display:block;width:100%;height:100%;position:absolute;top:-20%;background:radial-gradient(100% 28% at 50% 20%, #fff 15%, #fff0 100%);background-repeat:no-repeat;}
.wrap-css-drawing{display:inline-block;width:170px;height:150px;}
.ebok-css-drawing{width:100%;height:50%;background:linear-gradient(#999, #999) 0 0/17% 100%,
linear-gradient(#666, #666) 41% 0/17% 100%,
linear-gradient(red, red) 80% 0/17% 100%;background-repeat:no-repeat;-ms-transform:skewX(18deg);-webkit-transform:skewX(18deg);transform:skewX(18deg);}
.ebok-css-drawing.flip{-ms-transform:skewX(-18deg);-webkit-transform:skewX(-18deg);transform:skewX(-18deg);}
body.ebok-landing .ebok-css-drawing.flip{margin-top:-0.5px}
@media (max-width:991px) and (min-width:768px){.ebok .organiser-wrapper{top:-310px;right:0;}
    .ebok-person .drawing-col{left:-30px;text-align:right;}
    .grundare-wrapper h6{left:220px !important;font-size:20px;}
}
@media (max-width:767px) and (min-width:1px){.container.ebok .organiser-wrapper{top:80px !important;}
    .ebok-special .h2{font-size:16px;text-align:center;}
    .container.org.org-v2.v3.ebok{margin-bottom:35px;}
    .ebok-person{left:0;}
    .ebok-person .drawing-col{text-align:right !important;}
    .grundare-col{bottom:0 !important;}
    .grundare-wrapper h6{left:160px !important;font-size:22px;}
    .ebok-landing .lp-heading ul{line-height:1.5;}
}
@media (max-width:575px){.ebok-special{width:100%;}
    .ebok-special .h2{font-size:32px;}
    .ebok-landing .lp-heading .h2{line-height:1.5;}
    .ebok-landing .navbar-brand{width:100%;text-align:center;}
}
@media (max-width:375px){.ebok-special .h2{font-size:24px;}
}
@media (max-width:414px){.wrap-css-drawing{width:136px;height:120px;}
    .ebok-person .drawing-col{top:-20px;}
    .ebok-landing .row2{padding-top:65px;padding-bottom:35px;}
    .ebok-landing .organiser-wrapper > .organiser.ebok{width:355px !important;padding:20px;}
    a.ebok-cta{width:340px;height:130px;left:-14px;}
    .ebok-cta .btn{margin:23px 0;width:250px;}
}
@media (max-width:360px){.ebok-landing .organiser-wrapper > .organiser.ebok{width:345px !important;}
    a.ebok-cta{left:-18px;}
    .ebok-special .h2{font-size:24px;}
}
@media (max-width:320px){body.ebok-landing .ebok .organiser-wrapper{width:96%;}
    body.ebok-landing .grundare-wrapper h6{left:143px !important;font-size:20px;}
    .ebok-landing .lp-heading h2,
    .ebok-landing .lp-heading ul li{font-size:26px;}
    body.ebok-landing a.ebok-cta{max-width:290px;margin:auto;left:-10px;}
    body.ebok-landing a.ebok-cta button.btn.btn-primary{width:210px;}
    .ebok-cta:after{top:-21%;}
}
@media screen and (-ms-high-contrast:active), (-ms-high-contrast:none){.grundare-col{left:0;}
}
.mega{float:left;overflow:hidden;height:70px;}
.fake-mega-div{height:15px !important;}
.mega .dropbtn{font-size:16px;border:none;outline:none;color:white;padding:14px 16px;background-color:inherit;font:inherit;margin:0;}
.mega-row{margin-left:0;margin-right:0;}
.mega-content{position:absolute;width:100%;background-color:white;box-shadow:0 2px 6px 0 rgba(0,0,0,0.2);min-height:300px !important;z-index:-100;}
.column{float:left;padding:10px;background-color:white;}
.column a{float:none;color:black;padding:16px;text-decoration:none;display:block;text-align:left;}
.hide-hover{opacity:0;top:-500px;}
.mega-content .h6{font-size:20px;}
.title-address{padding-top:46px;padding-bottom:30px;max-width:265px;}
.address-wrapper{padding:25px 0 6px 26px;background-color:#f7f7f7;border-radius:3px;margin-bottom:50px;max-width:265px;}
.vertical-line{height:331px;width:1px;background-color:#dddddd;}
.vertical-line-wrapper{padding-top:20px;padding-bottom:20px;}
.column-links-wrapper-title .h6{color:rgb(33, 37, 41);}
.line-light{width:100%;border-bottom:1px solid #DDDDDD;margin-top:-5px;margin-bottom:10px;}
.line-light-bottom{width:100%;border-bottom:1px solid #DDDDDD;margin-bottom:7px;}
.product-list .sidebar-items-wrapper a.sidebar-topic-link:nth-child(n+7),
.product-single .sidebar-items-wrapper a.sidebar-topic-link:nth-child(n+7){display:none;}
.menu-item, #menu-main-menu{cursor:pointer;}
@media (max-width:991px){.mega-content.show-hover{display:none !important;}
}
#searchSwitcher:hover,
#searchSwitcherClose:hover,
#searchSwitcherCloseMob:hover,
.close-search-result:hover{color:#37373C !important;}
#mob-navbar-phone-icon-sec,
#mob-navbar-search-icon-sec{display:none;}
.hidden-search{display:none !important;}
#searchSwitcherClose{display:block;}
#mobNavSearchButton,
#searchSwitcherClose,
#searchSwitcherCloseMob,
.clear-search-input,
.close-search-result{font-size:26px;position:absolute;cursor:pointer;height:38px;color:#bbb;width:36px;line-height:40px;text-align:center;font-weight:normal;right:0;margin-top:0;}
#searchSwitcherCloseMob{display:none;top:16px;}
.clear-search-input, .close-search-result{right:30px;}
@media (min-width:1px) and (max-width:575px){#mob-navbar-phone-icon-sec,
#mob-navbar-search-icon-sec{display:block;}
    #mob-navbar-search-icon-sec{font-size:26px !important;}
    #whitediv{width:100%;height:100vh !important;height:auto;background-color:white;z-index:1045;display:none;top:30px;position:fixed !important;bottom:inherit;}
    .navbar-nav{display:none;}
    #mob-navbar-phone-icon-sec:hover{text-decoration:none;}
    #mob-navbar-phone-icon-sec:hover i{color:#37373C;}
    #mob-navbar-phone-icon-sec i,
    #mob-navbar-search-icon-sec{font-size:28px;color:#bbb;padding-top:5px;cursor:pointer;}
    #mob-navbar-phone-icon-sec i{padding-top:5px;}
    #mob-navbar-search-icon-sec{margin-right:15px;}
    #mob-navbar-phone-icon-sec i{margin-right:10px;}
    .close-search-result{top:16px;}
    .clear-search-input{right:55px;}
    #searchToShow{height:70px;}
    #searchSwitcherClose{display:none;}
    #searchSwitcherCloseMob{display:block;}
    #mobNavSearchButton{right:86px;font-weight:600;top:10px;}
    i#triggerPressSubmit{top:24px !important;}
    .tabs-wrapper{}
    .scrolling-wrapper{display:flex;flex-wrap:nowrap;overflow-x:auto;-webkit-overflow-scrolling:touch;}
    .tab-item{flex:0 0 auto;}
}
@media (min-width:576px) and (max-width:767px){#menuItemsWrapper{display:none;}
    #whitediv .row.no-gutters.justify-content-center{padding-top:0 !important;}
    #mobNavSearchButton{right:84px;}
}
@media (min-width:768px) and (max-width:991px){#menuItemsWrapper{display:none;}
    #mobNavSearchButton{right:84px;top:11px;}
    #whitediv{top:78px;}
    i#triggerPressSubmit{top:10px !important;}
}
@media (max-width: 374px) {.wrap-organizer-for-articles .organiser-wrapper {right: 0;} .wrap-organizer-for-articles button.btn.btn-outline-primary.big-cta-org-v2 {padding-left: 65px;} .wrap-organizer-for-articles .wrap-cta-person {left: 3%;} .wrap-organizer-for-articles #reco-rating {margin-left: 0;}} @media (max-width: 365px) { .wrap-organizer-for-articles div#org-first-div {height: 530px; }}
@media (max-width: 575px) and (min-width: 1px) { .post-content-wrapper * {word-break: break-word;} .swiper-slide .card-img-top {width:100% !important;height:100%;} #services-swipe-container .card {padding: 0;}}
@media (max-width: 1199px) and (min-width: 768px) {.cards-row.row.no-gutters.justify-content-around > div:first-of-type .card-body p > br:first-of-type {display: none;}}
@media (max-width: 367px) and (min-width: 348px) {#services-swipe-container .swiper-slide:last-child .card .card-text {padding-bottom: 23px;}}
.organiser-wrapper {max-width: 361px;}
.social-item {margin-bottom: 16px;}
.panel {padding: 0 18px;background-color: white;max-height: 0;overflow: hidden;transition: max-height 0.2s ease-out;box-sizing: content-box;}
