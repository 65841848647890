// override bootstrap variables
// /wp-content/themes/fenix4/node_modules/bootstrap/scss/_variables.scss

$font-family-sans-serif: 'Raleway', sans-serif;
$font-family-pp-gothic-medium: 'PP Right Gothic Medium', 'rawline';

$phoneSmall: 320px;
$phoneDef: 360px;
$phone: 576px;
$tablet720: 720px;
$tabletSmall: 768px;
$tabletMedium: 991px;
$tabletBig: 1024px;
$medium: 1200px;
$medium1280: 1280px;
$medium1300: 1300px;
$medium1367: 1367px;
$wide1440: 1440px;
$fullhd: 1920px;

$deepBlue: #002733;
$deepBlueHover: #002733c2;
$darkBlue: #465BC4;
$lightBlue: #C4D8FF;
$lightYellow: #FFEEC1;
$darkYellow: #D6A600;
$mediumBlue: #85AEFF;
$pink: #EC4DBE;