#mob-navbar-search-icon-sec:after,
#mobNavSearchButton:after,
#searchSwitcher:after {
    content: "Sök";
}

button#collapseButton:after,
button#collapseButtonClose:after{content:"Meny";}

#mob-navbar-phone-icon-sec .fenix-icon.icon-phone:after{content:"Ring";}

.prod-buttons-row-wrapper .city-selector-dropdown.show>.dropdown-menu {
     transform: translate3d(0, 38px, 0) !important;
}

@media (min-width:992px){
    #navCartIconWrapper:after{content:"Sparat";}
}

.funnel-close .fenix-icon.icon-cancel:after{content:'Tillbaka';}
.funnel-added-product .fenix-icon.icon-trash-alt-regular:after{content:'Ta bort';}